import { Box, Theme, Tooltip } from '@mui/material';
import React from 'react';
import LiabilityMonitoringTooltip from './LiabilityMonitoringTooltip';

type Props = {
  marketName: string;
  maxLiability: number;
  currentLiability: number;
  percentageValue: number;
  outcomes: { name: string; value: number }[];
};

const styles = {
  container: {
    display: 'flex',
    '& > :first-child': {
      borderLeft: (theme: Theme) => `1px solid ${theme.palette.primary.container}`,
    },
  },
  item: {
    borderBottom: (theme: Theme) => `1px solid ${theme.palette.primary.container}`,
    borderRight: (theme: Theme) => `1px solid ${theme.palette.primary.container}`,
    display: 'flex',
    alignItems: 'center',
    height: 50,
    fontSize: 14,
    width: 225,
    padding: '0 5px',
    justifyContent: 'center',
  },
  outcomesItem: {
    width: 40,
    height: 50,
    borderBottom: (theme: Theme) => `1px solid ${theme.palette.primary.container}`,
    borderRight: (theme: Theme) => `1px solid ${theme.palette.primary.container}`,
    display: 'flex',
    alignItems: 'center',
    padding: '0 5px',
    fontSize: 14,
    justifyContent: 'center',
  },
};

const LiabilityEventDetailsRow = ({ marketName, maxLiability, outcomes }: Props) => {
  const maxOutcome = outcomes.reduce((max, outcome) => (outcome.value > max.value ? outcome : max), outcomes[0]);
  const percentageValue = ((maxOutcome.value / maxLiability) * 100).toFixed(2);

  return (
    <Tooltip title={<LiabilityMonitoringTooltip outcomes={outcomes} />} placement="top">
      <Box sx={styles.container}>
        <Box sx={styles.item}>{marketName}</Box>
        <Box sx={styles.item}>{maxLiability}</Box>
        <Box sx={styles.item}>{maxOutcome.value}</Box>
        <Box sx={styles.outcomesItem}>{percentageValue}</Box>
      </Box>
    </Tooltip>
  );
};

export default LiabilityEventDetailsRow;
