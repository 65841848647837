import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { QueryKey } from '@tanstack/react-query';
import { unionBy } from 'lodash-es';
import { SyntheticEvent, useState } from 'react';
import { Control, Controller, FieldValues, Path, useController } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import useDebouncedSearch from '../../hooks/useDebouncedSearch';
import usePersist from '../../hooks/usePersist';
import { ApiService } from '../../utils/api';

type Item = {
  id: string;
  userId?: string;
  name?: string;
};

type FormAutocompleteProps<O extends Item, TField extends FieldValues> = {
  control: Control<TField>;
  name: Path<TField>;
  url: string;
  apiService?: ApiService;
  queryKey: QueryKey;
  queryParams?: Record<string, unknown>;
  placeholder?: string;
  required?: boolean;
  label?: string;
  error?: {
    message?: string;
  };
  disabled?: boolean;
  hookEnabled?: boolean;
  multiple?: boolean;
  getOptionLabel: (options: O[], value: string) => string;
  optionsFilter?: 'userId' | 'id' | 'name';
};

const FormAutocomplete = <O extends Item, TField extends FieldValues>({
  control,
  name,
  url,
  apiService,
  queryKey,
  queryParams,
  placeholder,
  required,
  label,
  error,
  disabled,
  hookEnabled,
  multiple = false,
  getOptionLabel,
  optionsFilter = 'id',
}: FormAutocompleteProps<O, TField>) => {
  const { getPersistData, setPersistData } = usePersist(false);
  const { data, search, isFetching, onSearch } = useDebouncedSearch<O>(
    apiService,
    url,
    queryKey,
    {
      additionalParams: queryParams,
    },
    hookEnabled
  );

  const { field } = useController({ name, control });
  const { value } = field;

  const options = unionBy(data?.selectedItems, data?.items, 'id');

  const autocompleteOptions = options?.reduce((acc: string[], item) => {
    const optionValue = item[optionsFilter];

    if (optionValue) {
      acc.push(optionValue);
    }

    return acc;
  }, []);

  const { pathname } = useLocation();
  const selectedOptionsLSKey = `${pathname}/${name}-options`;

  const savedData = getPersistData<{ options: O[] }>(selectedOptionsLSKey);
  const initialSelectedOptions = savedData?.options || [];

  // Since we are filtering with pagination on the server, we have to store the selected options to get the correct labeling
  const [selectedOptions, setSelectedOptions] = useState<O[]>(initialSelectedOptions);

  const onChange = (_: React.SyntheticEvent<Element, Event>, newValue: string[] | string | null) => {
    field.onChange(newValue);

    let newOption: O | undefined;

    if (Array.isArray(newValue)) {
      const lastElement = newValue[newValue.length - 1];
      newOption = options.find((option) => option[optionsFilter] === lastElement);
    } else {
      newOption = options.find((option) => option[optionsFilter] === newValue);
    }

    setPersistData(selectedOptionsLSKey, {
      options: initialSelectedOptions ? [...initialSelectedOptions, newOption] : initialSelectedOptions,
    });
    setSelectedOptions((prev) => (newOption ? [...prev, newOption] : prev));
    onSearch('');
  };

  const onInputChange = (_event: SyntheticEvent<Element, Event>, value: string) => {
    onSearch(value);
  };

  // TODO: Handle the console warning
  const isOptionEqualToValue = (option: string, value: string) => {
    return option === value;
  };

  return (
    <Controller
      control={control}
      name={name}
      render={() => {
        return (
          <Autocomplete
            id="asynchronous-autocomplete"
            isOptionEqualToValue={isOptionEqualToValue}
            multiple={multiple}
            value={value}
            options={autocompleteOptions || []}
            onChange={onChange}
            getOptionLabel={(id) => {
              return getOptionLabel([...options, ...selectedOptions] || [], id);
            }}
            filterOptions={(x) => x}
            clearOnBlur={false}
            loading={isFetching}
            disabled={disabled}
            onInputChange={onInputChange}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  value={search || ''}
                  label={label}
                  placeholder={placeholder}
                  error={!!error}
                  required={required}
                  helperText={error?.message}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              );
            }}
          />
        );
      }}
    />
  );
};

export default FormAutocomplete;
