import { Box, Typography } from '@mui/material';
import React from 'react';

type Outcome = {
  name: string;
  value: number;
};

type Props = {
  outcomes: Outcome[];
};

const styles = {
  container: {
    width: 500,
  },
  bold: {
    fontWeight: 600,
    fontSize: 18,
    marginBottom: '3px',
  },
  outcome: {
    display: 'flex',
    gap: '0 5px',
  },
};

const LiabilityMonitoringTooltip = ({ outcomes }: Props) => {
  return (
    <Box sx={styles.container}>
      <Typography sx={styles.bold}>Outcomes</Typography>
      {outcomes.map((outcome) => (
        <Box sx={styles.outcome} key={outcome.name}>
          <Typography>{outcome.name}</Typography>: <Typography>{outcome.value}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default LiabilityMonitoringTooltip;
