import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';
import { StyleObj } from '../../@types';

const styles: StyleObj = {
  btnIconWrapper: {
    backgroundColor: 'primary.main',
    borderRadius: '50%',
    width: '2.5rem',
    height: '2.5rem',
    display: 'grid',
    placeItems: 'center',
  },
};

const BtnIconwrapper = ({ children }: PropsWithChildren) => {
  return (
    <Box component="div" sx={styles.btnIconWrapper}>
      {children}
    </Box>
  );
};

export default BtnIconwrapper;
