import { type Rule } from 'sanity';

const promotionalTopContent = {
  name: 'PromotionalTopContent',
  title: 'Promotional Top Content',
  type: 'document',
  fields: [
    {
      name: 'title',
      title: 'Title',
      type: 'string',
      description: 'The title of the promotional content',
      validation: (Rule: Rule) => Rule.required(),
    },
    {
      name: 'upperText',
      title: 'Upper Text',
      type: 'string',
      description: 'Text in green rectangle above title',
      validation: (Rule: Rule) => Rule.required(),
    },
    {
      name: 'buttonText',
      title: 'Buton Text',
      type: 'string',
      description: 'Text written in the button',
      validation: (Rule: Rule) => Rule.required(),
    },
    {
      name: 'infoText',
      title: 'Info Text',
      type: 'text',
      description: 'Grey text positioned under the button',
      validation: (Rule: Rule) => Rule.required(),
    },
    {
      name: 'image',
      title: 'Image',
      type: 'image',
      description: 'The image for the promotional content',
      validation: (Rule: Rule) => Rule.required(),
    },
    {
      name: 'callToActionURL',
      title: 'Call to Action URL',
      type: 'url',
      description: 'The URL to which the call to action button should redirect',
      validation: (Rule: Rule) => Rule.required(),
    },
  ],
};

export default promotionalTopContent;
