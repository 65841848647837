import { NavigationTabOption } from '../@types';
import { BetStatus, ConditionEntityType, PublishStatus, TransactionType, WalletType } from '../@types/api';

export const SECOND = 1000;

export const SPORTS_BETTING_TABS: NavigationTabOption[] = [
  { label: 'Settings', value: 'settings', permission: 'manageBettingSettings' },
  {
    label: 'Offer management',
    value: 'offer-management',
    permission: 'manageOffer',
  },
];

export const BETTING_OFFER_TABS = [
  { label: 'Sports', value: 'sports' },
  { label: 'Competitions', value: 'competitions' },
  { label: 'Tournaments', value: 'tournaments' },
  { label: 'Participants', value: 'participants' },
  { label: 'Players', value: 'players' },
  { label: 'Markets', value: 'markets' },
  { label: 'Outcomes', value: 'outcomes' },
  { label: 'Results', value: 'results' },
  { label: 'Conditions', value: 'conditions' },
  { label: 'Market Groups', value: 'market-groups' },
  { label: 'Risk Management', value: 'risk-management' },
];

export const OFFER_MANAGEMENT_TABS: NavigationTabOption[] = [
  { label: 'Events', value: 'events', permission: 'manageEvents' },
  { label: 'Outrights', value: 'outrights', permission: 'manageEvents' },

  {
    label: 'Results',
    value: 'events-results',
    permission: 'manageEventsResults',
  },
  {
    label: 'Ticket Monitoring',
    value: 'ticket-monitoring',
    permission: 'accessTicketMonitoring',
  },
  {
    label: 'Ticket Authorization',
    value: 'ticket-authorization',
    permission: 'accessTicketAuthorization',
  },
  {
    label: 'Liability Monitoring',
    value: 'liability-monitoring',
    permission: 'accessLiabilityMonitoring',
  },
];

export const CRM_PAGE_TABS: NavigationTabOption[] = [
  { label: 'Partners', value: 'partners', permission: 'accessPartners' },
  { label: 'Players', value: 'players', permission: 'accessPlayers' },
];

export const CUSTOMER_WALLET_TABS: NavigationTabOption[] = [
  { label: 'Total Credit', value: 'total-credit' },
  { label: 'Payment Transaction', value: 'payment-transaction' },
  { label: 'Wallet Transaction', value: 'wallet-transaction' },
];

export const MY_WALLET_TABS = [{ label: 'Wallet Transaction', value: 'wallet-transaction' }];

export const USER_MANAGEMENT_TABS = [
  { label: 'Users', value: 'users' },
  { label: 'Groups', value: 'groups' },
];

export const LS_KEYS = {
  visibleColumns: 'visibleColumns',
  appFormFilter: 'appFormFilter',
};

export const QUERY_KEYS = {
  sports: 'sports',
  competitions: 'competitions',
  tournaments: 'tournaments',
  participants: 'participants',
  players: 'players',
  marketTypes: 'market-types',
  outcomeTypes: 'outcome-types',
  scoringUnits: 'scoring-units',
  events: 'events',
  customers: 'customer/search',
  markets: 'markets',
  eventParts: 'event-parts',
  results: 'events/results',
  eventPartScores: 'event-part-scores',
  conditions: 'conditions',
  conditionTypes: 'condition-types',
  conditionGroups: 'condition-groups',
  condtitionTemplates: 'condition-templates',
  marketTypeGroups: 'market-type-groups',
  marketGroups: 'market-groups',
  bettingSlips: 'betting-slips',
  bettingSlipsAnalytics: 'betting-slips-analytics',
  specialValues: 'special-values',
  agentCredentials: 'agent-credentials',
  crmNotes: 'notes',
  crmTasks: 'tasks',
  agents: 'agent',
  locationData: 'location',
  liabilities: 'risk-management/liabilities',
  userProductLiabilities: 'user-product-liabilities',
  riskFactors: 'risk-management/risk-factors',
  liabilitySettings: 'liability-settings',
  riskFactorDefaults: 'risk-factor-defaults',
  crmUsers: 'crm-users',
  crmCommissions: 'crm-commissions',
  riskSports: 'risk-management/sports',
  riskTournaments: 'risk-management/tournaments',
  riskMarkets: 'risk-management/market-types',
  credits: 'credits',
  userBalance: 'balance',
  paymentTransactions: 'transactions/payments',
  walletTransactions: 'transactions/wallets',
  adminTransactions: 'transactions',
  messages: 'messages',
  sentMessages: 'messages/sent',
  deletedMessages: 'messages/deleted',
  messagesCount: 'messagesCount',
  partners: 'partners',
  agentSearch: 'agent/search',
  event: 'event',
  punter: 'punter',
  dailyReports: 'reports/daily',
  riskPlayers: 'risk-management/players',
  agentsReportDetails: 'agents',
  jackpots: 'jackpots',
  stakeRanges: 'jackpots/stake-ranges',
  outrights: 'outrights',
} as const;

export const DEFAULT_PAGE_SIZE_OPTIONS = [25, 50, 100];

export const MODEL_TYPES = ['participant', 'player'] as const;

export const MODEL_TYPES_WITH_EVENT = [...MODEL_TYPES, 'event'] as const;

export const MODEL_TYPE_OPTIONS = [
  { id: 'participant', name: 'Participant' },
  { id: 'player', name: 'Player' },
  { id: 'event', name: 'Event' }, // BE stores this as a null, so when modelType is null, we are displaying Event in the UI
];

export const GENDER_ENUM = ['male', 'female', 'other'] as const;

export const GENDER_OPTIONS = [
  { id: 'male', name: 'Male' },
  { id: 'female', name: 'Female' },
  { id: 'other', name: 'Other' },
];

export const CURRENCY_ENUM = ['EUR'] as const;

export const CURRENCY_OPTIONS = [{ id: 'EUR', name: 'EUR' }];

export const PUBLISH_STATUS_ICON_COLORS = {
  new: '#AEA9B1',
  ready: '#FEDA2B',
  published: '#78B159',
} as Partial<Record<PublishStatus, string>>;

export const HAS_STARTED_OPTIONS = [
  { id: 1, name: 'Started' },
  { id: 0, name: 'Not started' },
];

export const IS_LIVE_OPTIONS = [
  { id: 1, name: 'In-play' },
  { id: 0, name: 'Pre-match' },
];

export const PUBLISH_STATUS_BASE_OPTIONS = [
  { id: 'new', name: 'New' },
  { id: 'ready', name: 'Ready' },
  { id: 'published', name: 'Published' },
];

export const CHANGED_STATUS_OPTIONS = [
  { id: 1, name: 'Changed' },
  { id: 0, name: 'Not changed' },
];

export const EVENT_SOURCE_OPTIONS = [
  { id: 'manual', name: 'Manual' },
  { id: 'odds_matrix_feed', name: 'Odds Matrix Feed' },
];

export const MATCH_STATUS_OPTIONS = [
  { id: 1, name: 'Enable event' },
  { id: 0, name: 'Disable event' },
];

export const SWITCH_CONTROL_OPTIONS = [
  { id: 1, name: 'Manual' },
  { id: 0, name: 'Automatic' },
];

export const DISPLAY_TYPE_OPTIONS = [
  { id: 1, name: 'Single' },
  { id: 0, name: 'Multiple' },
];

export const NOTE_TYPES = {
  RISK_MANAGEMENT: 'Risk Management',
  DEPOSITS: 'Deposits',
  WITHDRAWALS: 'Withdrawals',
  PRE_MATCH: 'Pre-Match',
  IN_PLAY: 'In-Play',
  CASINO: 'Casino',
  BONUSES: 'Bonuses',
};

export const NOTE_TYPE_OPTIONS = Object.entries(NOTE_TYPES).map(([id, name]) => ({ id, name }));

export const CUSTOMER_TASK_MESSAGE_TYPES = ['CALL', 'MESSAGE', 'APPOINTMENT'] as const;

export const CUSTOMER_TASK_MESSAGE_TYPE_OPTIONS = [
  { id: 'CALL', name: 'Call' },
  { id: 'MESSAGE', name: 'Message' },
  { id: 'APPOINTMENT', name: 'Appointment' },
];

export const CRM_TASKS_TABS = [
  {
    label: 'Upcoming tasks',
    value: 'upcoming',
  },
  {
    label: 'Past tasks',
    value: 'past',
  },
];

export const MESSAGES_TABS = [
  {
    label: 'Inbox',
    value: 'inbox',
  },
  {
    label: 'Sent',
    value: 'sent',
  },
  {
    label: 'Deleted',
    value: 'deleted',
  },
];

export const MARKET_STATUS_OPTIONS = [
  { id: 1, name: 'Activate market' },
  { id: 0, name: 'Deactivate market' },
];

export const MARKET_ALL_MATCHES_STATUS_OPTIONS = [
  { id: 1, name: 'Activate market for all matches' },
  { id: 0, name: 'Deactivate market for all matches' },
];

export const OUTCOME_STATUS_OPTIONS = [
  { id: 1, name: 'Enable outcome' },
  { id: 0, name: 'Disable outcome' },
];

export const OUTCOME_ALL_MATCHES_STATUS_OPTIONS = [
  { id: 1, name: 'Enable outcome for all matches' },
  { id: 0, name: 'Disable outcome for all matches' },
];

export const RESOLVE_MODEL_OPTIONS = [
  { id: 'automatic', name: 'Automatic' },
  { id: 'semi_automatic', name: 'Semi-Automatic' },
  { id: 'manual', name: 'Manual' },
];

export const TIME_DELAY_OPTIONS = [
  { id: 0, name: 'Instant' },
  { id: 1, name: '1 min' },
  { id: 2, name: '2 min' },
  { id: 5, name: '5 min' },
  { id: 10, name: '10 min' },
  { id: 15, name: '15 min' },
  { id: 30, name: '30 min' },
  { id: 45, name: '45 min' },
  { id: 60, name: '1 hour' },
];

export const MODEL_SELECT_FORM_VALUES = [
  {
    name: 'Automatic',
    id: 'automatic',
  },
  {
    name: 'Semi-Automatic',
    id: 'semi_automatic',
  },
  {
    name: 'Manual',
    id: 'manual',
  },
];

export const LANGUAGE_SELECT_VALUES = [
  { name: 'English', id: 'en' },
  { name: 'Serbian', id: 'sr' },
];

export const TRANSFER_STATUS_OPTIONS = [
  { id: 'new', name: 'New' },
  { id: 'ready', name: 'Ready' },
  { id: 'published', name: 'Published' },
];

export const RESOLVE_STATUS_COLORS = {
  open: '#AEA9B1',
  won: '#78B159',
  lost: '#D0021B',
  void: '#947BE7',
  tie: '#0062CC',
  half_won: ['#0062CC', '#78B159'],
  half_lost: ['#0062CC', '#D0021B'],
  deleted: '#FEAA2B',
} as Partial<Record<BetStatus | 'deleted', string | string[]>>;

export const RESOLVE_STATUS_OPTIONS = [
  { id: 'open', name: 'Open' },
  { id: 'won', name: 'Won' },
  { id: 'lost', name: 'Lost' },
  { id: 'void', name: 'Void' },
  { id: 'tie', name: 'Tie' },
  { id: 'half_won', name: 'Half Won' },
  { id: 'half_lost', name: 'Half Lost' },
];

export const VOID_OUTCOME_RESOLVE_STATUS_OPTION = { id: 'void', name: 'Void' };
export const DELETE_OUTCOME_RESOLVE_STATUS_OPTION = { id: 'delete', name: 'Delete' };

export const EVENT_RESULTS_FILTER_OPTIONS = [
  { id: 'with_results', name: 'With results' },
  { id: 'no_results', name: 'Without results' },
  { id: 'incomplete', name: 'Incomplete results' },
];

export const FEATURED_WARNING_MESSAGE =
  'Only one tournament can be set as featured, are you sure that you want to set this one?';

export const CONDITION_ENTITY_SELECT_OPTIONS = [
  { id: 'sport', name: 'Sport' },
  { id: 'competition', name: 'Competition' },
  { id: 'tournament', name: 'Tournament' },
  { id: 'market_type', name: 'Market' },
  { id: 'event', name: 'Event' },
  { id: 'punter', name: 'Punter' },
];

export const CONDITION_TYPE_ENTITY_SELECT_OPTIONS = [
  ...CONDITION_ENTITY_SELECT_OPTIONS,
  { id: 'ticket', name: 'Ticket' },
];

export const CONDITION_ENTITY_LABELS: Record<ConditionEntityType, string> = {
  sport: 'Sport',
  competition: 'Competition',
  tournament: 'Tournament',
  market_type: 'Market',
  event: 'Event',
  punter: 'Punter',
  ticket: 'Ticket',
};

export const CONDITION_NAME_SELECT_OPTIONS = [
  'Max Payment',
  'Max Winning',
  'Max Selections',
  'Max Stake',
  'Max Odds',
  'Max Market Selections',
  'Min Ticket Selections',
  'Min Odds Other Selections',
  'Min Stake',
  'Min Odds',
  'Similarity Percentage',
  'Trigger Number',
].map((name) => ({ id: name, name }));

export const DISPLAY_SELECT_OPTIONS = [
  {
    name: 'Match',
    id: 'match',
  },
  {
    name: 'Tournament',
    id: 'tournament',
  },
];

export const CONDITIONS_TABS = [
  { label: 'Conditions type', value: 'conditions-type' },
  { label: 'Conditions groups', value: 'conditions-groups' },
  { label: 'Sports', value: 'sports' },
  { label: 'Competitions', value: 'competitions' },
  { label: 'Tournaments', value: 'tournaments' },
  { label: 'Markets', value: 'markets' },
  { label: 'Punters', value: 'punters' },
  { label: 'Tickets', value: 'tickets' },
];

export const PARTNERS_REPORT_TABS = [
  { label: 'Managers', value: 'managers' },
  { label: 'Agents', value: 'agents' },
];

export const CONDITIONS_COMBINATION_MESSAGE = {
  'Max Odds': 'Max Odds and Max Stake Combination',
  'Min Odds': 'Min Odds and Min Stake',
};

export const MARKET_TYPE_USED_FOR_OPTIONS = [
  { id: 'pre_match', name: 'Pre-match' },
  { id: 'in_play', name: 'In-play' },
];

export const TICKET_MONITORING_REFETCH_INTERVAL_OPTIONS = [
  { id: 0, name: 'No refresh' },
  { id: 5 * SECOND, name: '5 seconds' },
  { id: 10 * SECOND, name: '10 seconds' },
  { id: 30 * SECOND, name: '30 seconds' },
  { id: 60 * SECOND, name: '60 seconds' },
  { id: 300 * SECOND, name: '5 minutes' },
];

export const BETTING_SLIP_FROM_OPTIONS = [
  { id: 'Pre-match', name: 'Pre-match' },
  { id: 'In-play', name: 'In-play' },
  { id: 'Mix', name: 'Mix' },
];

export const BETTING_SLIP_TYPE_OPTIONS = [
  { id: 'Single', name: 'Single' },
  { id: 'Multiple', name: 'Multiple' },
  { id: 'System', name: 'System' },
];

export const BETTING_SLIP_STATUS_OPTIONS = [
  { id: 'open', name: 'Open' },
  { id: 'void', name: 'Void' },
  { id: 'won', name: 'Won' },
  { id: 'lost', name: 'Lost' },
  { id: 'cashout', name: 'Cash out' },
];

export const BETTING_SLIP_ACCEPT_STATUS_OPTIONS = [
  { id: 'pending', name: 'Pending' },
  { id: 'accepted', name: 'Accepted' },
  { id: 'rejected', name: 'Rejected' },
  { id: 'admin_cancelled', name: 'Admin Cancelled' },
];

export const DEVICE_OPTIONS = [
  { id: 'mobile', name: 'Mobile' },
  { id: 'desktop', name: 'Desktop' },
];

export const BETTING_SLIP_IS_WAYS_OPTIONS = [
  { id: 1, name: 'Yes' },
  { id: 0, name: 'No' },
];

export const BETTING_SLIP_VERIFICATION_OPTIONS = [
  { id: 'manual', name: 'Manual' },
  { id: 'automatic', name: 'Automatic' },
];

export const OPERATOR_COUNTDOWN_DURATION = 300;
export const PUNTER_COUNTDOWN_DURATION = 60;

export const BACKOFFICE_WS_ROOM_ID = 'backoffice.users';

export const RISK_MANAGEMENT_TABS = [
  { label: 'Risk settings', value: 'risk-settings' },
  { label: 'Sports', value: 'sports' },
  { label: 'Tournaments', value: 'tournaments' },
  { label: 'Markets', value: 'markets' },
  { label: 'Players', value: 'players' },
];

export const PRODUCT_TYPES = ['pre-match', 'in-play'] as const;

export const PRODUCT_TYPES_OPTIONS = [
  { id: 'pre-match', name: 'Pre-match' },
  { id: 'in-play', name: 'In-play' },
];

export const RISK_FACTOR_ENTITIES = ['sport', 'tournament', 'market_type', 'punter'] as const;

export const RISK_FACTOR_ENTITY_OPTIONS = [
  { id: 'sport', name: 'Sport' },
  { id: 'tournament', name: 'Tournament' },
  { id: 'market_type', name: 'Market' },
  { id: 'punter', name: 'Punter' },
] as const;

const riskFactorDefaultValue = {
  riskFactor: '',
  minTrigger: 0,
  maxTrigger: 0,
};

export const RISK_FACTOR_DEFAULT_VALUES = {
  sport: riskFactorDefaultValue,
  tournament: riskFactorDefaultValue,
  market_type: {
    riskFactor: riskFactorDefaultValue.riskFactor,
  },
  punter: riskFactorDefaultValue,
};

export const PARTNER_COMMISSION_TYPE_OPTIONS = [
  {
    id: 'POSITION',
    name: 'Position',
  },
  {
    id: 'PROFIT',
    name: 'Profit',
  },
];

export const PLAYER_COMMISSION_TYPE_OPTIONS = [
  ...PARTNER_COMMISSION_TYPE_OPTIONS,
  {
    id: 'PER_PICK',
    name: 'Per pick',
  },
];

export const CURRENCY = {
  code: 'EUR',
  symbol: '€',
};

export const PREDEFINED_DATE_RANGE_OPTIONS = [
  { id: 'today', name: 'Today' },
  { id: 'yesterday', name: 'Yesterday' },
  { id: 'this_week', name: 'This Week' },
  { id: 'last_week', name: 'Last Week' },
  { id: 'last_7_days', name: 'Last 7 Days' },
  { id: 'last_30_days', name: 'Last 30 Days' },
  { id: 'this_month', name: 'This Month' },
  { id: 'last_month', name: 'Last Month' },
] as const;

export const TRANSACTION_TYPE_OPTIONS: Record<WalletType, { id: TransactionType; name: string }[]> = {
  main: [
    { id: 'deposit', name: 'Deposit' },
    { id: 'withdrawal', name: 'Withdrawal' },
    { id: 'other', name: 'Other' },
  ],
  bonus: [{ id: 'bonus', name: 'Bonus' }],
};

export const WALLET_TYPE_OPTIONS = (bonusWalletDisabled: boolean) => [
  {
    id: 'main',
    name: 'Main Balance',
  },
  {
    id: 'bonus',
    name: 'Bonus Credits',
    disabled: bonusWalletDisabled,
  },
];

export const WALLET_TRANSACTION_TYPE_OPTIONS = [
  { id: 'deposit', name: 'Deposit' },
  { id: 'withdrawal', name: 'Withdrawal' },
  { id: 'transfer', name: 'Transfer' },
  { id: 'bonus', name: 'Bonus' },
  { id: 'other', name: 'Other' },
  { id: 'cashout', name: 'Cashout' },
  { id: 'place_bet', name: 'Place Bet' },
  { id: 'won_bet', name: 'Won Bet' },
  { id: 'won_to_lost_bet', name: 'Won to Lost Bet' },
];

export const PAYMENT_TRANSACTION_TYPE_OPTIONS = WALLET_TRANSACTION_TYPE_OPTIONS.slice(0, 5);

export const PRODUCT_REPORTS_TABS: NavigationTabOption[] = [
  { label: 'Daily', value: 'daily' },
  { label: 'Sportsbook', value: 'sportsbook' },
  { label: 'Players', value: 'players' },
  { label: 'Partners', value: 'partners' },
  { label: 'Payments', value: 'payments' },
  { label: 'Bonus', value: 'bonus' },
  { label: 'Jackpot', value: 'jackpot' },
];

export const SPORTSBOOK_TABS = [
  { label: 'Sports', value: 'sports' },
  { label: 'Tournaments', value: 'tournaments' },
  { label: 'Markets', value: 'markets' },
];

export const BETTING_SLIP_REOFFERED_OPTIONS = [
  { id: 1, name: 'Yes' },
  { id: 0, name: 'No' },
];

export const BETTING_SLIP_AUTHORIZED_TICKETS_OPTIONS = [
  { id: 'reoffered', name: 'Reoffered' },
  { id: 'verified', name: 'Verified' },
];

export const CREATE_MESSAGE_USERS_OPTIONS_MAP = {
  Administrator: [
    {
      id: 'Agents',
      name: 'Agents',
    },
    {
      id: 'Managers',
      name: 'Managers',
    },
    {
      id: 'Players',
      name: 'Players',
    },
  ],
  Manager: [
    {
      id: 'Agents',
      name: 'Agents',
    },
    {
      id: 'Players',
      name: 'Players',
    },
  ],
  Agent: [
    {
      id: 'Players',
      name: 'Players',
    },
  ],
};
export const SPORTSBOOK_SOURCE_OPTIONS = [
  { id: 'desktop', name: 'Desktop' },
  { id: 'mobile', name: 'Mobile' },
];

export const SPORTSBOOK_WALLET_TYPE = [
  { id: 'main', name: 'Real Money Bets' },
  { id: 'bonus', name: 'Credit Money Bets' },
];

export const SPORTSBOOK_FROM_OPTIONS = [
  { id: 'Pre-match', name: 'Pre-match' },
  { id: 'In-play', name: 'In-play' },
  { id: 'Mix', name: 'Mix' },
];

export const PAYMENTS_TABS = [
  { label: 'Total Payments', value: 'total' },
  { label: 'Payment List', value: 'list' },
];

export const PAYMENTS_USER_OPTIONS = [
  { id: 'player', name: 'Player' },
  { id: 'manager', name: 'Manager' },
  { id: 'agent', name: 'Agent' },
];

export const PAYMENT_STATUS_OPTIONS = [
  { id: 'completed', name: 'Completed' },
  { id: 'canceled', name: 'Canceled' },
];

export const FILTER_LIABILITY_BETTING_OFFER = [
  { id: 'pre-match', name: 'Pre-match' },
  { id: 'in-play', name: 'In-play' },
];

export const BETSLIP_CONTROL_DELAY_OPTIONS = [
  { id: 1, name: '1 Sec' },
  { id: 3, name: '3 Sec' },
  { id: 5, name: '5 Sec' },
  { id: 7, name: '7 Sec' },
  { id: 10, name: '10 Sec' },
];

export const BETTING_TYPES = [
  'Single',
  'Double',
  'Treble',
  '4 Fold',
  '5 Fold',
  '6 Fold',
  '7 Fold',
  '8 Fold',
  '9 Fold',
  '10 Fold',
  '11 Fold',
  '12 Fold',
  '13 Fold',
  '14 Fold',
  '15 Fold',
  '16 Fold',
  '17 Fold',
  '18 Fold',
  '19 Fold',
  '20 Fold',
];

export const MARKET_TYPE_TYPE_OPTIONS = [
  {
    id: 'match',
    name: 'Match',
  },
  {
    id: 'outright',
    name: 'Outright',
  },
  {
    id: 'match_and_outright',
    name: 'Match & Outright',
  },
];
