import { Stack } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import { ENTITY_EDIT_TABS_MAP, EditTabRecord, EntityEditTabType } from '../../config';
import { StyledTab, StyledTabs } from '../atoms/StyledTabs';
import TabPanel from '../atoms/TabPanel';

type EditTabsProps = {
  editEntity?: EntityEditTabType;
  selectedTab: number;
  handleTabChange: (event: React.SyntheticEvent, newTabIndex: number) => void;
};

const EditTabs = ({ children, editEntity, selectedTab, handleTabChange }: PropsWithChildren<EditTabsProps>) => {
  const location = useLocation();
  const currentEditEntity = editEntity || (location.pathname.split('/').pop() as EntityEditTabType);

  const currentEntityEditTabs = ENTITY_EDIT_TABS_MAP[currentEditEntity] || [];

  return (
    <>
      <StyledTabs value={selectedTab} onChange={handleTabChange} variant="fullWidth" indicatorWidth={44}>
        <StyledTab label="Details" />
        {currentEntityEditTabs.map((tab: EditTabRecord, index: number) => (
          <StyledTab key={index} label={tab.label} />
        ))}
      </StyledTabs>
      <TabPanel value={selectedTab} index={0}>
        <Stack spacing={2}>{children}</Stack>
      </TabPanel>
      {currentEntityEditTabs.map((tab: EditTabRecord, index: number) => {
        return (
          <TabPanel key={index} value={selectedTab} index={index + 1}>
            <tab.component />
          </TabPanel>
        );
      })}
    </>
  );
};

export default EditTabs;
