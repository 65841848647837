import { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import {
  CompetitionList,
  ConditionEntityType,
  EventList,
  MarketTypeList,
  SportList,
  TournamentList,
} from '../../@types/api';
import { QUERY_KEYS } from '../../constants';
import { useModal } from '../../contexts/ModalContext';
import { useInvalidateQuery } from '../../hooks/useInvalidateQuery';
import useMutateData from '../../hooks/useMutateData';
import { ConditionFormData } from '../../schema';
import ConditionsValuesForm from './forms/conditions/ConditionsValuesForm';

const CONDITION_ENTITY_MAP: Record<string, ConditionEntityType> = {
  sports: 'sport',
  competitions: 'competition',
  tournaments: 'tournament',
  punters: 'punter',
  tickets: 'ticket',
  markets: 'market_type',
  outrights: 'event',
  events: 'event',
};

const ConditionsTab = () => {
  const { item, closeModal } = useModal<SportList | CompetitionList | TournamentList | MarketTypeList | EventList>();
  const location = useLocation();
  const invalidateData = useInvalidateQuery();

  const entity = location.pathname.split('/').pop();

  const conditionModelType = CONDITION_ENTITY_MAP[entity as ConditionEntityType];

  const { createData: createCondition } = useMutateData('conditions', [QUERY_KEYS.conditions]);

  const onSave = async (data: ConditionFormData) => {
    createCondition(data, () => {
      closeModal();
      invalidateData([entity]);
    });
  };

  return (
    <Fragment>
      <ConditionsValuesForm
        onCancel={closeModal}
        onSave={onSave}
        conditionGroupId={item?.conditionGroupId || null}
        conditionModelType={conditionModelType}
        modelId={item?.id}
      />
    </Fragment>
  );
};

export default ConditionsTab;
