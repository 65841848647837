import { Box, IconButton, Typography } from '@mui/material';
import { MarketGroupsList } from '../../@types/api';
import { getNameToDisplay } from '../../schema/helpers';
import { ChevronRight } from '@mui/icons-material';

const styles = {
  container: {
    width: '30%',
    mt: 11,
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    backgroundColor: 'primary.main',
    borderRadius: '8px 8px 0 0',
    padding: '13px',
    color: 'background.light',
    fontWeight: 700,
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
  },
  marketType: {
    padding: '12px',
    borderLeft: '1px solid #E7F0E7',
    borderRight: '1px solid #E7F0E7',
    borderBottom: '1px solid #E7F0E7',
  },
  chevronIconBtn: {
    p: 0,
    pr: 0.5,
  },
};

type Props = {
  marketGroupData: MarketGroupsList | null;
  handleClose: () => void;
};

const MarketGroupDetails = ({ marketGroupData, handleClose }: Props) => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.header}>
        <IconButton onClick={handleClose} sx={styles.chevronIconBtn} color="inherit">
          <ChevronRight />
        </IconButton>
        <Typography variant="h5">
          {marketGroupData?.sport.name}/{marketGroupData?.name}/{marketGroupData?.usedFor}
        </Typography>
      </Box>
      <Box>
        {marketGroupData?.marketTypes.map((marketType) => (
          <Typography sx={styles.marketType} variant="body3" key={marketType.id}>
            {getNameToDisplay(marketType)}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

export default MarketGroupDetails;
