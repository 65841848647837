import { AddCircle, Cancel, CheckCircle, Info } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import IconRenderer from '../components/atoms/IconRenderer';
import Switch from '../components/atoms/Switch';
import TableIconContainer from '../components/atoms/TableIconContainer';
import EditTableItem from '../components/organisms/EditTableItem';
import { createColumn } from '../utils';

type HasSportIcon = {
  sport: {
    icon: string;
  };
};

type HasIsActive = {
  isActive: boolean;
};

type HasId = {
  id: string;
};

type IsActiveColumnOptions<T> = {
  updateData: (id: string, data: Partial<T>) => void;
  alwaysVisible?: boolean;
};

type EditColumnOptions = {
  deleteData?: (id: string) => void;
  addOutcome?: (marketId: string, sportId: string) => void;
  columnName?: string;
};

export const createSportIconColumn = <T extends HasSportIcon>(): GridColDef => {
  return createColumn('sportIcon', 'Icon', {
    sortable: false,
    valueGetter: (params: GridValueGetterParams<T>) => params.row.sport.icon,
    renderCell: (params) => {
      return (
        <TableIconContainer>
          <IconRenderer name={params.value} type="sport" />
        </TableIconContainer>
      );
    },
  });
};

export const createIsActiveColumn = <T extends HasIsActive>(
  options: Omit<GridColDef, 'field' | 'headerName'> & IsActiveColumnOptions<T>
): GridColDef => {
  const { updateData, alwaysVisible } = options;
  return createColumn('isActive', 'Status', {
    alwaysVisible,
    renderCell: (params) => (
      <Switch
        value={params.value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          updateData(params.row.id, { isActive: e.target.checked } as Partial<T>);
        }}
        name="status"
      />
    ),
  });
};

export const createEditColumn = <T extends HasId>(options: EditColumnOptions = {}): GridColDef => {
  const { deleteData, addOutcome, columnName = 'Edit' } = options;

  return createColumn('edit', columnName, {
    sortable: false,
    renderCell: (params) => (
      <>
        {addOutcome && (
          <IconButton onClick={() => addOutcome(params.row.id, params.row.sport.id)} color="primary">
            <Tooltip title="Add outcome">
              <AddCircle />
            </Tooltip>
          </IconButton>
        )}
        <EditTableItem<T> handleDelete={deleteData ? () => deleteData(params.row.id) : undefined} item={params.row} />
      </>
    ),
  });
};

export const createDescriptionColumn = () =>
  createColumn('description', 'Description', {
    sortable: false,
    renderCell: (params) =>
      params.row.description && (
        <TableIconContainer>
          <Tooltip title={params.row.description}>
            <Info color="primary" />
          </Tooltip>
        </TableIconContainer>
      ),
  });

export const createStatusDisplayColumn = () =>
  createColumn('isActive', 'Status', {
    renderCell: (params) =>
      params.row.isActive ? (
        <CheckCircle color="primary" fontSize="medium" />
      ) : (
        <Cancel color="error" fontSize="medium" />
      ),
  });
