import { useEffect, useState } from 'react';
import { Studio, defineConfig } from 'sanity';
import { structureTool } from 'sanity/structure';
import { schemaTypes } from '../../config/sanity/schemaTypes';
import Log from '../../utils/logger';

const _config = defineConfig({
  plugins: [structureTool()],
  name: 'default',
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID as string,
  theme: {},
  dataset: process.env.REACT_APP_SANITY_DATASET as string,
  root: false,
  schema: {
    types: schemaTypes,
  },
  basePath: '/cms',
});

const CMSStudioPage = () => {
  const [config, setConfig] = useState(_config);

  useEffect(() => {
    // Start fetching the theme in parallel with the Studio auth loading
    const importTheme = async () => {
      try {
        const { theme } = await import(
          // The webpackIgnore tells webpack to not attempt bundling this dynamic import,
          // and instead let it run natively in the browser at runtime
          /* webpackIgnore: true */
          process.env.REACT_APP_SANITY_THEME_URL || ''
        );
        setConfig((config) => ({ ...config, theme }));
      } catch (error) {
        Log('Failed to load theme:', error);
      }
    };
    importTheme();
  }, []);

  return <Studio config={config} scheme="light" />;
};

export default CMSStudioPage;
