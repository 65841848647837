import { Replay } from '@mui/icons-material';
import { Button, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { DialogType } from '../../../@types';
import { Customer } from '../../../@types/api';
import { LS_KEYS, PAYMENTS_USER_OPTIONS, QUERY_KEYS } from '../../../constants';
import { AdditionalQueryParams } from '../../../hooks/usePagination';
import usePersist from '../../../hooks/usePersist';
import FormSelect from '../../molecules/FormSelect';
import FormAutocomplete from '../FormAutocomplete';
import { useEffect } from 'react';

export const styles = {
  display: 'flex',
  flexDirection: 'column-reverse',
  alignItems: 'center',
  gap: '2',
};

const defaultValues = {
  type: 'player',
  managerIds: [],
  agentIds: [],
  playerIds: [],
};

export type FilterSportsbookData = {
  type?: string;
  managerIds?: string[];
  agentIds?: string[];
  playerIds?: string[];
};

type Props = DialogType & {
  changeQuery: (data: AdditionalQueryParams) => void;
};

const FilterPaymentReportsTotal = ({ changeQuery, closeModal }: Props) => {
  const { pathname } = useLocation();
  const page = pathname.split('/').pop();

  const { setPersistData, getPersistData, deletePersistData } = usePersist(false);

  const formDataKey = `${LS_KEYS.appFormFilter}-${pathname}`;
  const getSavedData = () => getPersistData<FilterSportsbookData>(formDataKey) || defaultValues;

  const { control, handleSubmit, reset, watch } = useForm<FilterSportsbookData>({
    defaultValues: getSavedData(),
  });

  const type = watch('type');

  const handleFilterReset = () => {
    deletePersistData(formDataKey);
    reset(defaultValues);
    changeQuery(defaultValues);
    closeModal?.();
  };

  const onFormSubmit = (data: FilterSportsbookData) => {
    setPersistData<FilterSportsbookData>(formDataKey, data);
    changeQuery(data);
    closeModal?.();
  };

  useEffect(() => {
    reset({ ...defaultValues, type });
  }, [reset, type]);

  return (
    <>
      <DialogTitle variant="h4" gutterBottom>
        Filter {page}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <FormSelect control={control} name="type" options={PAYMENTS_USER_OPTIONS} defaultValue="player" />
          <FormAutocomplete<Customer, FilterSportsbookData>
            name="playerIds"
            control={control}
            queryKey={[QUERY_KEYS.customers]}
            apiService="crm"
            url="customer/search"
            multiple
            getOptionLabel={(options, value) => {
              const option = options.find((option) => option?.userId === value);
              return option ? `${option.firstName} ${option.lastName} (${option.username})` : '';
            }}
            label="Players"
            optionsFilter="userId"
            disabled={type !== 'player'}
          />
          <FormAutocomplete<Customer, FilterSportsbookData>
            name="managerIds"
            control={control}
            queryKey={[QUERY_KEYS.agentSearch]}
            apiService="crm"
            multiple
            url="agent/search"
            queryParams={{ roleTypes: ['Manager'] }}
            getOptionLabel={(options, value) => {
              const option = options.find((option) => option.userId === value);
              return option ? `${option.fullName}` : '';
            }}
            label="Managers"
            optionsFilter="userId"
            disabled={type !== 'manager'}
          />
          <FormAutocomplete<Customer, FilterSportsbookData>
            name="agentIds"
            control={control}
            queryKey={[QUERY_KEYS.agentSearch]}
            apiService="crm"
            url="agent/search"
            multiple
            queryParams={{ roleTypes: ['Agent'] }}
            getOptionLabel={(options, value) => {
              const option = options.find((option) => option.userId === value);
              return option ? `${option.fullName}` : '';
            }}
            optionsFilter="userId"
            label="Agents"
            disabled={type !== 'agent'}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleFilterReset} startIcon={<Replay />} size="small">
          Reset filters
        </Button>
        <Button variant="outlined" onClick={closeModal}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit(onFormSubmit)}>
          Save
        </Button>
      </DialogActions>
    </>
  );
};

export default FilterPaymentReportsTotal;
