import { Replay } from '@mui/icons-material';
import { Button, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { DialogType } from '../../../@types';
import { Customer, Partner } from '../../../@types/api';
import { LS_KEYS, PAYMENT_STATUS_OPTIONS, QUERY_KEYS, TRANSACTION_TYPE_OPTIONS } from '../../../constants';
import { AdditionalQueryParams } from '../../../hooks/usePagination';
import usePersist from '../../../hooks/usePersist';
import FormSelect from '../../molecules/FormSelect';
import FormAutocomplete from '../FormAutocomplete';

export const styles = {
  display: 'flex',
  flexDirection: 'column-reverse',
  alignItems: 'center',
  gap: '2',
};

const defaultValues = {
  playerIds: [],
  partnerIds: [],
  type: undefined,
  status: undefined,
};

export type FormData = {
  playerIds?: string[];
  partnerIds?: string[];
  type?: string;
  status?: string;
};

type Props = DialogType & {
  changeQuery: (data: AdditionalQueryParams) => void;
};

const FilterPaymentReportsList = ({ changeQuery, closeModal }: Props) => {
  const { pathname } = useLocation();
  const page = pathname.split('/').pop();

  const { setPersistData, getPersistData, deletePersistData } = usePersist(false);

  const formDataKey = `${LS_KEYS.appFormFilter}-${pathname}`;
  const getSavedData = () => getPersistData<FormData>(formDataKey) || defaultValues;

  const { control, handleSubmit, reset } = useForm<FormData>({
    defaultValues: getSavedData(),
  });

  const handleFilterReset = () => {
    deletePersistData(formDataKey);
    reset(defaultValues);
    changeQuery(defaultValues);
    closeModal?.();
  };

  const onFormSubmit = (data: FormData) => {
    setPersistData<FormData>(formDataKey, data);
    changeQuery(data);
    closeModal?.();
  };

  return (
    <>
      <DialogTitle variant="h4" gutterBottom>
        Filter {page}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <FormAutocomplete<Customer, FormData>
            name="playerIds"
            control={control}
            queryKey={[QUERY_KEYS.customers]}
            apiService="crm"
            url="customer/search"
            multiple
            getOptionLabel={(options, value) => {
              const option = options.find((option) => option.userId === value);
              return option ? `${option.firstName} ${option.lastName} (${option.username})` : '';
            }}
            label="Players"
            optionsFilter="userId"
          />
          <FormAutocomplete<Partner, FormData>
            name="partnerIds"
            control={control}
            queryKey={[QUERY_KEYS.agentSearch]}
            apiService="crm"
            url="agent/search"
            label="Partner"
            multiple
            getOptionLabel={(options, value) => {
              const option = options.find((option) => option.userId === value);
              return option ? option.fullName : '';
            }}
            optionsFilter="userId"
          />
          <FormSelect label="Type" name="type" control={control} options={TRANSACTION_TYPE_OPTIONS.main} />
          <FormSelect label="Status" name="status" control={control} options={PAYMENT_STATUS_OPTIONS} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleFilterReset} startIcon={<Replay />} size="small">
          Reset filters
        </Button>
        <Button variant="outlined" onClick={closeModal}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit(onFormSubmit)}>
          Save
        </Button>
      </DialogActions>
    </>
  );
};

export default FilterPaymentReportsList;
