import axios, {
  AxiosHeaders,
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
  Method,
  RawAxiosRequestHeaders,
} from 'axios';
import { getAccessToken } from 'neofusion-fe-shared';

export type MethodsHeaders = Partial<
  {
    [Key in Method as Lowercase<Key>]: AxiosHeaders;
  } & { common: AxiosHeaders }
>;

const feed = axios.create({
  baseURL: process.env.REACT_APP_API_FEED_URL,
});
const crm = axios.create({
  baseURL: process.env.REACT_APP_API_CRM_URL,
});
const wallet = axios.create({
  baseURL: process.env.REACT_APP_API_WALLET_URL,
});

export type UserRole = 'Administrator' | 'Manager' | 'Agent';

type UserCredentials = {
  agentId: string;
  role: UserRole;
};

let cachedAgentCredentials: UserCredentials | null = null;
let agentCredentialsFetched = false;

export const getAgentCredentials = async () => {
  if (cachedAgentCredentials && agentCredentialsFetched) {
    return cachedAgentCredentials;
  }

  const { data } = await crm.get('/agent/by-user');

  if (data && data.length > 0) {
    const { agentid: agentId, role } = data[0];

    cachedAgentCredentials = { agentId, role };
    agentCredentialsFetched = true;

    return cachedAgentCredentials;
  } else {
    return null;
  }
};

const requestHandler = async (config: InternalAxiosRequestConfig) => {
  const token = await getAccessToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

feed.interceptors.request.use(requestHandler);
crm.interceptors.request.use(requestHandler);
wallet.interceptors.request.use(requestHandler);

const axiosInstance = {
  feed,
  crm,
  wallet,
} as const;

export type ApiService = keyof typeof axiosInstance;

const executeRequest = async (
  method: 'get' | 'post' | 'patch' | 'delete',
  path: string,
  body: unknown | null,
  apiService: ApiService = 'feed',
  queryParams?: unknown,
  headers?: (RawAxiosRequestHeaders & MethodsHeaders) | AxiosHeaders
) => {
  let updatedHeaders = headers;

  const agentCredentials = await getAgentCredentials();

  if (apiService === 'crm') {
    if (agentCredentials) {
      updatedHeaders = {
        ...headers,
        agentId: agentCredentials.agentId,
        role: agentCredentials.role,
      };
    }
  }

  const requestOptions: AxiosRequestConfig = {
    headers: updatedHeaders,
    params: queryParams,
  };

  if (method === 'get' || method === 'delete') {
    const { data } = await axiosInstance[apiService][method](path, { ...requestOptions, data: body });
    return data;
  } else {
    const { data } = await axiosInstance[apiService][method](path, body, requestOptions);
    return data;
  }
};

export const getData = async (
  path: string,
  queryParams?: unknown,
  apiService: ApiService = 'feed',
  headers?: (RawAxiosRequestHeaders & MethodsHeaders) | AxiosHeaders
) => {
  return executeRequest('get', path, null, apiService, queryParams, headers);
};

export const postData = async (
  path: string,
  body: unknown,
  apiService: ApiService = 'feed',
  headers?: (RawAxiosRequestHeaders & MethodsHeaders) | AxiosHeaders
) => {
  return executeRequest('post', path, body, apiService, null, headers);
};

export const patchData = async (
  path: string,
  body: unknown,
  apiService: ApiService = 'feed',
  headers?: (RawAxiosRequestHeaders & MethodsHeaders) | AxiosHeaders
) => {
  return executeRequest('patch', path, body, apiService, null, headers);
};

export const deleteData = async (
  path: string,
  apiService: ApiService = 'feed',
  headers?: (RawAxiosRequestHeaders & MethodsHeaders) | AxiosHeaders,
  body?: unknown | null
) => {
  return executeRequest('delete', path, body, apiService, null, headers);
};

export const hasApiMorePages = (count: number, page: number, limit: number) => {
  const pagesCount = Math.ceil(count / limit);
  return page < pagesCount;
};
