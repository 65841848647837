import { type Rule } from 'sanity';

const promotionalSideContent = {
  name: 'PromotionalSideContent',
  title: 'Promotional Side Content',
  type: 'document',
  fields: [
    {
      name: 'title',
      title: 'Title',
      type: 'string',
      description: 'Text shown while hovering',
      validation: (Rule: Rule) => Rule.required(),
    },
    {
      name: 'image',
      title: 'Image',
      type: 'image',
      description: 'The image for the promotional content',
      validation: (Rule: Rule) => Rule.required(),
    },
    {
      name: 'callToActionURL',
      title: 'Call to Action URL',
      type: 'url',
      description: 'The URL to which the call to action button should redirect',
      validation: (Rule: Rule) => Rule.required(),
    },
  ],
};

export default promotionalSideContent;
