import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { StyleObj } from '../../../@types';
import { PaginatedData, RiskFactor, RiskManagementEntity, RiskManagementEntityType } from '../../../@types/api';
import {
  PATH_TO_ENTITY_TYPE_SINGULAR_MAP,
  getQueryKey,
  getRiskManagementColumns,
} from '../../../config/riskManagement';
import { QUERY_KEYS } from '../../../constants';
import { useModal } from '../../../contexts/ModalContext';
import { useCellEditMode } from '../../../hooks/useCellEditMode';
import useMutateData from '../../../hooks/useMutateData';
import { usePagination } from '../../../hooks/usePagination';
import useSort from '../../../hooks/useSort';
import { getData } from '../../../utils/api';
import FormModal from '../../modals/FormModal';
import { defaultValues } from '../../organisms/forms/FilterPreMatchSettingsData';
import TableTemplate from '../../templates/TableTemplate';

const makeStyles = (): StyleObj => ({
  container: {
    height: '100%',
    maxHeight: '100%',
    minHeight: 0,
  },
  switchBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: 0,
    position: 'relative',
    top: 4,
  },
  productTypeToggleBtn: {
    textTransform: 'capitalize',
    height: 48,
  },
});

export const URL_TO_RISK_MANAGEMENT_MODEL_TYPE_MAP: Record<string, RiskManagementEntityType> = {
  sports: 'sports',
  tournaments: 'tournaments',
  markets: 'market-types',
  players: 'punters',
};

const RiskManagementEntityPage = () => {
  const [productValue, setProductValue] = useState('pre-match');

  const location = useLocation();
  const pathSegment = location.pathname.split('/').pop();

  const entityType = pathSegment && URL_TO_RISK_MANAGEMENT_MODEL_TYPE_MAP[pathSegment];
  const { data, updateQueryParams, isFetching, isLoading, changeQuery, refetch } = usePagination(
    `risk-management/${entityType}`,
    {
      page: 1,
      limit: 25,
      product: 'pre-match',
    }
  );
  const defaultRiskFactor = data?.defaultRiskFactor;

  const { updateData: updateRiskData } = useMutateData(`risk-management/entity-risk-factors/${productValue}`, [
    getQueryKey(entityType),
  ]);

  const { data: riskFactors } = useQuery([QUERY_KEYS.riskFactors], {
    queryFn: (): Promise<PaginatedData<RiskFactor>> => getData('risk-management/risk-factors'),
    select: (data) => data.items,
  });

  const { handleSort } = useSort(changeQuery);
  const { cellModesModel, handleCellClick, handleCellModesModelChange } = useCellEditMode();

  const styles = makeStyles();
  const { openModal } = useModal();

  const openSettingsDialog = (row: RiskManagementEntity) => {
    openModal(<FormModal item={{ ...row, productValue }} form="player-risk-management" />);
  };

  const { columns, defaultColumns } = getRiskManagementColumns(
    entityType as RiskManagementEntityType,
    riskFactors || [],
    refetch,
    updateRiskData,
    openSettingsDialog,
    defaultRiskFactor
  );
  const handleSearch = (value: string | null) => {
    changeQuery({ search: value });
  };

  const handleChange = (_event: React.MouseEvent<HTMLElement>, value: string) => {
    if (value && productValue !== value) {
      setProductValue(value);
      changeQuery({ product: value });
    }
  };

  useEffect(() => {
    changeQuery({ ...defaultValues, search: null });
  }, [pathSegment, changeQuery]);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.switchBox}>
        <ToggleButtonGroup exclusive value={productValue} onChange={handleChange}>
          <ToggleButton sx={styles.productTypeToggleBtn} value="pre-match">
            Pre-Match
          </ToggleButton>
          <ToggleButton sx={styles.productTypeToggleBtn} value="in-play">
            In Play
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <TableTemplate
        rows={data?.items || []}
        columns={columns}
        loading={isFetching || isLoading}
        rowCount={data?.count || 0}
        defaultVisibleColumns={defaultColumns}
        handlePaginationModelChange={updateQueryParams}
        handleSearch={handleSearch}
        handleSort={handleSort}
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        onCellClick={handleCellClick}
        changeQuery={changeQuery}
        processRowUpdate={(updatedRow, originalRow) => {
          if (!entityType) return;

          if (updatedRow.minTrigger !== originalRow.minTrigger) {
            updateRiskData(
              `${PATH_TO_ENTITY_TYPE_SINGULAR_MAP[entityType]}/${updatedRow.id}`,
              {
                minTrigger: Number(updatedRow.minTrigger),
              },
              () => refetch()
            );
          }

          if (updatedRow.maxTrigger !== originalRow.maxTrigger) {
            updateRiskData(
              `${PATH_TO_ENTITY_TYPE_SINGULAR_MAP[entityType]}/${updatedRow.id}`,
              {
                maxTrigger: Number(updatedRow.maxTrigger),
              },
              () => refetch()
            );
          }
          return updatedRow;
        }}
      />
    </Box>
  );
};

export default RiskManagementEntityPage;
