import { Box, Dialog } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../../constants';
import { PaginatedData } from '../../@types/api';
import { getData } from '../../utils/api';
import { GridColDef } from '@mui/x-data-grid';
import { createColumn } from '../../utils';
import StyledDataGrid from './StyledDataGrid';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

const styles = {
  dialog: {
    '& .MuiDialog-paper': {
      width: '100%',
      borderRadius: 2,
      boxShadow: 0,
      backgroundColor: 'transparent',
    },
  },
  table: {
    '& .MuiDataGrid-row:nth-of-type(even)': {
      backgroundColor: 'background.light',
      '&:hover': {
        backgroundColor: 'background.light',
      },
    },
    '& .MuiDataGrid-row:nth-of-type(odd)': {
      backgroundColor: '#fff',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    alignSelf: 'flex-end',
    color: 'primary.container',
    cursor: 'pointer',
  },
};

type Props = {
  agentId: string;
  open: boolean;
  closeModal: () => void;
  agentName: string;
};

type AgentReportDetailsResponse = {
  1: number;
  2: number;
  3: number;
  total: number;
  '4-7': number;
  '8-9': number;
  '10+': number;
};

const AgentReportsDetails = ({ agentId, open, closeModal, agentName }: Props) => {
  const { data } = useQuery([QUERY_KEYS.agentsReportDetails], {
    queryFn: (): Promise<PaginatedData<AgentReportDetailsResponse>> =>
      getData(`reports/partners/agents/${agentId}`, {
        limit: 100,
      }),
    enabled: !!agentId,
  });

  const columns: GridColDef[] = [
    createColumn('', `Partner: ${agentName}`, { sortable: false }),
    createColumn('perPick', 'Per Pick', { sortable: false }),
    createColumn('1', '1', { sortable: false }),
    createColumn('2', '2', { sortable: false }),
    createColumn('3', '3', { sortable: false }),
    createColumn('4-7', '4-7', { sortable: false }),
    createColumn('8-9', '8-9', { sortable: false }),
    createColumn('10+', '10+', { sortable: false }),
    createColumn('total', 'Totals', { sortable: false }),
  ];

  return (
    <Dialog sx={styles.dialog} open={open} fullWidth maxWidth="xl">
      <Box sx={styles.container}>
        <CancelRoundedIcon fontSize="large" sx={styles.icon} onClick={closeModal} />
        <StyledDataGrid
          columns={columns}
          rows={data?.items || []}
          getRowId={(params) => params.name}
          sx={styles.table}
        />
      </Box>
    </Dialog>
  );
};

export default AgentReportsDetails;
