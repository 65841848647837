import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import dayjs from 'dayjs';
import { LiabilityEventDetails } from '../../@types';
import LiabilityEventDataDetails from './LiabilityEventDataDetails';
import { ChevronRight } from '@mui/icons-material';

const styles = {
  container: {
    width: '50%',
  },
  header: {
    backgroundColor: 'primary.main',
    color: '#fff',
    borderRadius: '8px 8px 0 0',
    padding: '10px 15px',
    height: '45px',
    display: 'flex',
    alignItems: 'center',
    gap: '7px',
  },
  eventDetails: {
    backgroundColor: 'background.lighter',
    padding: '10px 15px',
    height: '45px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  eventValues: {},
  divider: {
    height: '10px',
    width: '2px',
    backgroundColor: '#fff',
    borderRadius: '8px',
  },
  text: {
    fontWeight: 700,
    fontSize: '14px',
  },
  eventDetailsDivider: {
    height: '15px',
    width: '2px',
    borderRadius: '8px',
    backgroundColor: '#1D1B20',
  },
  eventDetailsText: {
    fontWeight: 600,
    fontSize: '14px',
  },
  actions: {
    height: '87px',
  },
};

type Props = {
  eventDetails: LiabilityEventDetails;
  onClose: () => void;
};

const LiabilityEvent = ({ eventDetails, onClose }: Props) => {
  const { id, eventName, startDate, competitionName, sportName, tournamentName } = eventDetails;

  const displayStartDate = dayjs(startDate).format('DD/MM/YYYY HH:mm');

  return (
    <Box sx={styles.container}>
      <Box sx={styles.actions}></Box>
      <Box>
        <Box sx={styles.header}>
          <IconButton onClick={onClose} color="inherit">
            <ChevronRight />
          </IconButton>
          <Typography sx={styles.text}>{eventName}</Typography>
          <Box sx={styles.divider} />
          <Typography sx={styles.text}>{displayStartDate}</Typography>
        </Box>
        <Box sx={styles.eventDetails}>
          <Typography sx={styles.eventDetailsText}>{sportName}</Typography>
          <Box sx={styles.eventDetailsDivider} />
          <Typography sx={styles.eventDetailsText}>{competitionName}</Typography>
          <Box sx={styles.eventDetailsDivider} />
          <Typography sx={styles.eventDetailsText}>{tournamentName}</Typography>
        </Box>
        <LiabilityEventDataDetails eventId={id} />
      </Box>
    </Box>
  );
};

export default LiabilityEvent;
