import { zodResolver } from '@hookform/resolvers/zod';
import { EditOutlined } from '@mui/icons-material';
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { mapValues } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CommissionType, StyleObj } from '../../../@types';
import { CRMUserData } from '../../../@types/api';
import { PARTNER_COMMISSION_TYPE_OPTIONS, PLAYER_COMMISSION_TYPE_OPTIONS, QUERY_KEYS } from '../../../constants';
import { useCRMUser } from '../../../contexts/CRMUserContext';
import { useInvalidateQuery } from '../../../hooks/useInvalidateQuery';
import useMutateData from '../../../hooks/useMutateData';
import usePermissions from '../../../hooks/usePermissions';
import { commissionSchema } from '../../../schema';
import { getData } from '../../../utils/api';
import FormNumberInput from '../../molecules/FormNumberInput';
import FormSelect from '../../molecules/FormSelect';

const baseInputStyle = {
  backgroundColor: 'white',
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid rgba(0, 83, 55, 0.20)',
    borderRadius: 1,
  },
};

const styles: StyleObj = {
  tableContainer: {
    mt: 2,
    boxShadow: 0,
    border: '1px solid rgba(0, 83, 55, 0.20)',
    backgroundColor: 'white',
  },
  gridContainer: {
    '& .MuiFormLabel-root': {
      letterSpacing: '0.5px',
    },
  },
  userName: {
    typography: 'h5',
    fontSize: 16,
    letterSpacing: 0.8,
  },
  input: {
    ...baseInputStyle,
    '& .MuiOutlinedInput-notchedOutline > legend > span': {
      paddingRight: '12px',
    },
  },
  inputWithoutPadding: baseInputStyle,
  perPickInput: {
    '& fieldset': { border: 'none' },
    '& input': {
      textAlign: 'center',
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      color: 'error.main',
    },
  },
  rightBorderCell: { borderRight: '1px solid rgba(0, 83, 55, 0.20)' },
  editButton: {
    p: 0,
    ':hover': {
      background: 'transparent',
    },
  },
};

const DEFAULT_FORM_DATA: CommissionFormData = {
  playerCommissionType: 'PER_PICK',
  playerCommissionPercentage: '',
  partnerCommissionType: 'POSITION',
  partnerCommissionPercentage: '',
  picks: {
    '1': '',
    '2': '',
    '3': '',
    '4-7': '',
    '8-9': '',
    '10+': '',
  },
};

type CommissionFormData = {
  playerCommissionType: CommissionType;
  playerCommissionPercentage: string;
  partnerCommissionType: CommissionType;
  partnerCommissionPercentage: string;
  picks: Picks<string>;
};

type Picks<T> = {
  '1': T;
  '2': T;
  '3': T;
  '4-7': T;
  '8-9': T;
  '10+': T;
};

type CommissionResponse = {
  type: CommissionType;
  percentage: number;
  picks: Picks<number>;
};

const PICKS_CONFIG: (keyof Picks<string>)[] = ['1', '2', '3', '4-7', '8-9', '10+'];

const CRMCommissionTab = () => {
  const [isEditTableMode, setIsEditTableMode] = useState(false);
  const { selectedCRMUser } = useCRMUser();
  const selectedCRMUserId = selectedCRMUser?.id;

  const invalidateData = useInvalidateQuery();
  const { hasPermission } = usePermissions();

  const canEditCommission = hasPermission('editPartnerCommission');

  const { data: userData } = useQuery<CRMUserData>([QUERY_KEYS.crmUsers, selectedCRMUserId], {
    queryFn: () => getData(`agent/by-id/${selectedCRMUserId}`, undefined, 'crm'),
    enabled: !!selectedCRMUserId,
  });

  const { data: commissionData } = useQuery<CommissionResponse>([QUERY_KEYS.crmCommissions, selectedCRMUserId], {
    queryFn: () => getData(`agent/commission/${selectedCRMUserId}`, undefined, 'crm'),
    enabled: !!selectedCRMUserId,
  });

  const { createData, updateData } = useMutateData(
    'agent/commission',
    [QUERY_KEYS.crmCommissions, selectedCRMUserId],
    'crm'
  );

  const initialCommissionData: CommissionFormData = useMemo(() => {
    return {
      partnerCommissionType: 'POSITION',
      partnerCommissionPercentage: '',
      playerCommissionType: commissionData?.type || 'PER_PICK',
      playerCommissionPercentage: commissionData?.percentage?.toString() || '',
      picks: mapValues(commissionData?.picks, (value) => value.toString()),
    };
  }, [commissionData]);

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isDirty, errors },
  } = useForm<CommissionFormData>({
    defaultValues: initialCommissionData || DEFAULT_FORM_DATA,
    resolver: zodResolver(commissionSchema),
  });

  useEffect(() => {
    if (commissionData) {
      reset(initialCommissionData);
    } else {
      reset(DEFAULT_FORM_DATA);
    }
  }, [commissionData, initialCommissionData, reset]);

  const isPerPickSelected = watch('playerCommissionType') === 'PER_PICK';

  const prepareData = (data: CommissionFormData) => {
    const picks = mapValues(data.picks, (value) => Number(value));

    // Partner commissions are a placeholder for now, so we're only sending player commission data
    return {
      type: data.playerCommissionType,
      percentage: !isPerPickSelected ? Number(data.playerCommissionPercentage) : undefined,
      picks,
    };
  };

  const onSave = (data: CommissionFormData) => {
    const preparedData = prepareData(data);

    const onSuccess = () => {
      invalidateData([QUERY_KEYS.crmCommissions, selectedCRMUserId]);
      setIsEditTableMode(false);
    };

    if (selectedCRMUserId) {
      if (commissionData) {
        updateData(selectedCRMUserId, preparedData, onSuccess, 'Commission updated successfully');
      } else {
        createData({ ...preparedData, agentId: selectedCRMUserId }, onSuccess, 'Commission created successfully');
      }
    }
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" p={1}>
        <Typography sx={styles.userName}>{userData?.fullName}</Typography>
        <Typography variant="h6">{userData?.teamLead?.fullName}</Typography>
      </Stack>
      <Grid container spacing={2} mt={1} sx={styles.gridContainer}>
        <Grid item xs={9.5}>
          <FormSelect
            name="partnerCommissionType"
            label="Partner commission type"
            control={control}
            options={PARTNER_COMMISSION_TYPE_OPTIONS}
            sx={styles.input}
          />
        </Grid>
        <Grid item xs={2.5}>
          <FormNumberInput
            control={control}
            name="partnerCommissionPercentage"
            label="%"
            allowDecimals
            valueAsString
            sx={styles.inputWithoutPadding}
          />
        </Grid>
        <Grid item xs={9.5}>
          <FormSelect
            name="playerCommissionType"
            label="Player commission type"
            control={control}
            options={PLAYER_COMMISSION_TYPE_OPTIONS}
            sx={styles.input}
          />
        </Grid>
        {!isPerPickSelected && (
          <Grid item xs={2.5}>
            <FormNumberInput
              control={control}
              name="playerCommissionPercentage"
              label="%"
              allowDecimals
              valueAsString
              sx={styles.inputWithoutPadding}
            />
          </Grid>
        )}
      </Grid>
      {isPerPickSelected && (
        <TableContainer component={Paper} sx={styles.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={styles.rightBorderCell}>
                  <Typography variant="h6" fontWeight={600}>
                    Picks
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="h6" textAlign="end" width="52%">
                      %
                    </Typography>
                    {canEditCommission && (
                      <IconButton sx={styles.editButton} onClick={() => setIsEditTableMode(true)}>
                        <EditOutlined color={isEditTableMode ? 'disabled' : 'primary'} />
                      </IconButton>
                    )}
                  </Stack>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {PICKS_CONFIG.map((pick) => (
                <TableRow key={pick}>
                  <TableCell align="center" sx={styles.rightBorderCell}>
                    <Typography variant="h6" fontWeight={600}>
                      {pick}
                    </Typography>
                  </TableCell>
                  <TableCell align="center" padding="none">
                    <FormNumberInput
                      control={control}
                      name={`picks.${pick}`}
                      fullWidth
                      allowDecimals
                      valueAsString
                      error={errors.picks?.[pick]}
                      showHelperText={false}
                      disabled={!isEditTableMode}
                      sx={styles.perPickInput}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {isEditTableMode && (
        <Stack direction="row" mt={2} spacing={1} justifyContent="flex-end">
          <Button variant="outlined" onClick={() => setIsEditTableMode(false)}>
            Cancel
          </Button>
          <Button onClick={handleSubmit(onSave)} disabled={!isDirty}>
            Save
          </Button>
        </Stack>
      )}
    </>
  );
};

export default CRMCommissionTab;
