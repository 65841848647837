import { PortableTextEditor } from '@sanity/portable-text-editor';
import { type Rule } from 'sanity';

const markdownBody = {
  name: 'PortableTextContent',
  title: 'PortableText Content',
  type: 'document',
  fields: [
    {
      name: 'title',
      title: 'Title',
      type: 'string',
      description: 'The title of the content',
      validation: (Rule: Rule) => Rule.required(),
    },
    {
      name: 'body',
      title: 'Body',
      type: 'array',
      of: [{ type: 'block' }],
      inputComponent: PortableTextEditor,
      description: 'The main content body written in PortableText syntax',
    },
    {
      name: 'path',
      title: 'Path',
      type: 'string',
      description:
        'Used only for Content Page, if callToActionURL gets used then it will try to forward. Path name that will be shown on URL. Example: /betting_rules',
    },
    {
      name: 'callToActionURL',
      title: 'Call to Action URL',
      type: 'url',
      description: 'The URL to which the call to action button should forward. ',
    },
  ],
};

export default markdownBody;
