import React from 'react';
import { useForm } from 'react-hook-form';
import usePersist from '../../../hooks/usePersist';
import { useLocation } from 'react-router-dom';
import { LS_KEYS, QUERY_KEYS } from '../../../constants';
import { Button, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { AdditionalQueryParams } from '../../../hooks/usePagination';
import { DialogType } from '../../../@types';
import { Replay } from '@mui/icons-material';
import { Customer, Partner } from '../../../@types/api';
import FormAutocomplete from '../FormAutocomplete';

type FilterPlayerReportData = {
  fromTimestamp?: number | Dayjs;
  toTimestamp?: number | Dayjs;
  playerIds?: string;
  partnerIds?: string;
};
const defaultValues = {
  fromTimestamp: dayjs().startOf('day').valueOf(),
  toTimestamp: dayjs().endOf('day').valueOf(),
  playerIds: undefined,
  partnerIds: undefined,
};
type Props = DialogType & {
  changeQuery: (data: AdditionalQueryParams) => void;
};

const FilterPlayerReport = ({ changeQuery, closeModal }: Props) => {
  const { pathname } = useLocation();
  const page = pathname.split('/').pop();
  const formDataKey = `${LS_KEYS.appFormFilter}-${pathname}`;
  const { setPersistData, getPersistData, deletePersistData } = usePersist(false);
  const getSavedData = () => getPersistData<FilterPlayerReportData>(formDataKey) || defaultValues;

  const { control, handleSubmit, reset } = useForm<FilterPlayerReportData>({ defaultValues: getSavedData() });

  const handleFilterReset = () => {
    deletePersistData(formDataKey);
    reset(defaultValues);
    changeQuery(defaultValues);

    closeModal?.();
  };

  const onFormSubmit = (data: FilterPlayerReportData) => {
    setPersistData<FilterPlayerReportData>(formDataKey, data);
    changeQuery(data);
    closeModal?.();
  };

  return (
    <>
      <DialogTitle variant="h4" gutterBottom>
        Filter {page}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <FormAutocomplete<Partner, FilterPlayerReportData>
            name="partnerIds"
            control={control}
            queryKey={[QUERY_KEYS.agentSearch]}
            apiService="crm"
            url="agent/search"
            label="Partner"
            multiple
            getOptionLabel={(options, value) => {
              const option = options.find((option) => option?.userId === value);
              return option ? option.fullName : '';
            }}
            optionsFilter="userId"
          />
          <FormAutocomplete<Customer, FilterPlayerReportData>
            name="playerIds"
            control={control}
            queryKey={[QUERY_KEYS.customers]}
            apiService="crm"
            url="customer/search"
            multiple
            getOptionLabel={(options, value) => {
              const option = options.find((option) => option?.userId === value);
              return option ? `${option.firstName} ${option.lastName} (${option.username})` : '';
            }}
            label="Player"
            optionsFilter="userId"
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleFilterReset} startIcon={<Replay />} size="small">
          Reset filters
        </Button>
        <Button variant="outlined" onClick={closeModal}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit(onFormSubmit)}>
          Save
        </Button>
      </DialogActions>
    </>
  );
};

export default FilterPlayerReport;
