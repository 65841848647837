import React from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { DialogType } from '../../@types';
import { AdditionalQueryParams } from '../../hooks/usePagination';
import { LS_KEYS, QUERY_KEYS, SPORTSBOOK_FROM_OPTIONS, SPORTSBOOK_WALLET_TYPE } from '../../constants';
import usePersist from '../../hooks/usePersist';
import FormAutocomplete from './FormAutocomplete';
import { Partner } from '../../@types/api';
import FormSelect from '../molecules/FormSelect';
import FilterDialogLayout from '../layouts/FilterDialogLayout';

type FilterAgentsReportData = {
  managerIds?: string;
  from?: string;
  walletType?: string;
};
const defaultValues = {
  managerIds: undefined,
  from: undefined,
  walletType: undefined,
};
type Props = DialogType & {
  changeQuery: (data: AdditionalQueryParams) => void;
};

const FilterAgentReport = ({ changeQuery, closeModal }: Props) => {
  const { pathname } = useLocation();
  const page = pathname.split('/').pop();
  const formDataKey = `${LS_KEYS.appFormFilter}-${pathname}`;
  const { setPersistData, getPersistData, deletePersistData } = usePersist(false);
  const getSavedData = () => getPersistData<FilterAgentsReportData>(formDataKey) || defaultValues;

  const { control, handleSubmit, reset } = useForm<FilterAgentsReportData>({ defaultValues: getSavedData() });

  const handleFilterReset = () => {
    deletePersistData(formDataKey);
    reset(defaultValues);
    changeQuery(defaultValues);

    closeModal?.();
  };

  const handleClose = () => {
    closeModal?.();
  };

  const onFormSubmit = (data: FilterAgentsReportData) => {
    setPersistData<FilterAgentsReportData>(formDataKey, data);
    changeQuery(data);
    closeModal?.();
  };

  return (
    <FilterDialogLayout
      label={page || ''}
      onSave={handleSubmit(onFormSubmit)}
      onClose={handleClose}
      onReset={handleFilterReset}
    >
      <FormAutocomplete<Partner, FilterAgentsReportData>
        name="managerIds"
        control={control}
        queryKey={[QUERY_KEYS.agentSearch]}
        apiService="crm"
        url="customer/search"
        label="Customers"
        multiple
        getOptionLabel={(options, value) => {
          const option = options.find((option) => option?.userId === value);
          return option ? option.fullName : '';
        }}
        optionsFilter="userId"
      />
      <FormSelect name="from" control={control} label="From" options={SPORTSBOOK_FROM_OPTIONS} />
      <FormSelect name="walletType" control={control} label="Bets" options={SPORTSBOOK_WALLET_TYPE} />
    </FilterDialogLayout>
  );
};

export default FilterAgentReport;
