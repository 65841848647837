import { FormHelperText } from '@mui/material';
import { DateOrTimeView, DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { Control, Controller, FieldError, FieldErrorsImpl, FieldValues, Merge, Path } from 'react-hook-form';

const BASE_VIEWS = ['year', 'month', 'day'];
const TIME_VIEWS = ['hours', 'minutes'];

type DateTimeSelectProps<T extends FieldValues> = DateTimePickerProps<Dayjs> & {
  name: Path<T>;
  control: Control<T>;
  label?: string;
  error?: Merge<FieldError, FieldErrorsImpl<T>> | undefined;
  disableTimePicker?: boolean;
  disablePast?: boolean;
  required?: boolean;
  fullWidth?: boolean;
};

const DateTimeSelect = <T extends FieldValues>({
  name,
  control,
  error,
  label,
  disablePast,
  disableTimePicker,
  required,
  fullWidth,
  ...rest
}: DateTimeSelectProps<T>) => {
  const views = disableTimePicker ? BASE_VIEWS : [...BASE_VIEWS, ...TIME_VIEWS];

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => {
        return (
          <DateTimePicker
            label={label || 'Date'}
            onChange={onChange}
            value={value ? dayjs(value) : null}
            ampm={false}
            disablePast={disablePast}
            views={views as DateOrTimeView[]}
            slotProps={{
              textField: {
                sx: {
                  width: fullWidth ? '100%' : 'auto',
                },
                error: !!error,
                required,
                helperText: <FormHelperText sx={{ ml: 0 }}>{error?.message as string}</FormHelperText>,
                FormHelperTextProps: {
                  component: 'div',
                  sx: {
                    marginTop: 0,
                  },
                },
              },
            }}
            {...rest}
          />
        );
      }}
    />
  );
};
export default DateTimeSelect;
