import { hexToRgb } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid';

import { ColumnFields, MarketsGroupedByMarketType } from '../@types';
import { BetStatus, BettingSlipAcceptStatus, BettingSlipStatus, Market } from '../@types/api';
import { AdditionalDataGridColumnProps } from '../components/molecules/CustomizeTable';
import { COMMON_COLUMN_PROPS } from '../constants/table';

export const hexToRgba = (hex: string, alpha: number) => {
  const rgb = hexToRgb(hex);

  return rgb.replace('rgb', 'rgba').replace(')', `, ${alpha})`);
};

export const formatBreadcrumb = (pathSegment: string) => {
  if (pathSegment.length <= 3) {
    return pathSegment.toUpperCase();
  }

  return pathSegment.replace(/-/g, ' ');
};

export const createColumn = (
  field: ColumnFields | string,
  headerName: string,
  specificProps?: AdditionalDataGridColumnProps & Omit<GridColDef, 'field' | 'headerName'>
) => ({
  field,
  headerName,
  ...COMMON_COLUMN_PROPS,
  ...specificProps,
});

export const getBool = (value?: number | boolean): boolean | undefined => {
  // Return the boolean representation for both numbers and booleans.
  // If value is undefined, !!undefined returns false, but we want to maintain undefined.
  return value === undefined ? undefined : !!value;
};

export const prepareBoolValue = (value: number | boolean | undefined | null): boolean | undefined => {
  return value !== undefined && value !== null ? !!value : undefined;
};
export const getBettingSlipAcceptStatusColor = (status: BettingSlipAcceptStatus) => {
  switch (status) {
    case 'accepted':
      return '#78B159';
    case 'pending':
      return '#F7B64E';
    case 'rejected':
      return '#DC362E';
    case 'admin_cancelled':
      return '#938F99';
    default:
      return '#000000';
  }
};

export const getBettingSlipStatusColor = (status: BettingSlipStatus) => {
  switch (status) {
    case 'open':
      return '#2B7FFE';
    case 'won':
      return '#14805C';
    case 'lost':
      return '#B3261E';
    case 'void':
      return '#9F8FEF';
    case 'cashout':
      return '#F7B61E';
    default:
      return '#000000';
  }
};

export const getBetStatusColor = (status: BetStatus) => {
  switch (status) {
    case 'won':
      return '#14805C';
    case 'lost':
      return '#B3261E';
    case 'void':
      return '#9F8FEF';
    case 'open':
      return '#2B7FFE';
    case 'half_won':
      return '#FFC107';
    case 'half_lost':
      return '#FFC107';
    default:
      return '#000000';
  }
};

const isMultipleDisplayType = (market: Market) => {
  return !market.isSingleDisplayType && market.specialValues.length > 0;
};

export const formatMarketsData = (markets?: Market[]) => {
  const result: (Market | MarketsGroupedByMarketType)[] = [];

  markets?.forEach((market) => {
    if (isMultipleDisplayType(market)) {
      const existingItem = result.find((item) => item.id === market.marketTypeId);

      if (existingItem && 'markets' in existingItem) {
        existingItem.markets.push(market);
      } else {
        const marketName = market.marketType?.shortName || market.marketType?.name || '';
        result.push({
          id: market.marketTypeId,
          marketType: market.marketTypeId,
          name: `${marketName}, ${market.marketType?.eventPartName}`,
          markets: [market],
          isCollapsedMode: market.isCollapsedMode,
          position: market.position,
          specialValues: market.specialValues,
          outcomes: market.outcomes,
        });
      }
    } else {
      result.push(market);
    }
  });

  return result;
};
