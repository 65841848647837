import { Box } from '@mui/material';
import React from 'react';
import LiabilityEventDetailsRow from '../atoms/LiabilityEventDetailsRow';
import { QUERY_KEYS } from '../../constants';
import { useQuery } from '@tanstack/react-query';
import { getData } from '../../utils/api';
import { PaginatedData } from '../../@types/api';

const styles = {
  container: {
    width: '100%',
  },
  header: {
    display: 'flex',
    backgroundColor: 'background.lightGreen',
    borderTop: '1px solid rgba(0, 83, 55, 0.20)',
    borderBottom: '1px solid rgba(0, 83, 55, 0.20)',
    '& > :first-child': {
      borderLeft: '1px solid rgba(0, 83, 55, 0.20)',
    },
  },
  headerItem: {
    height: 50,
    width: 225,
    borderRight: '1px solid rgba(0, 83, 55, 0.20)',
    fontSize: 14,
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  percentageHeaderItem: {
    width: 40,
    height: 50,
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '1px solid rgba(0, 83, 55, 0.20)',
  },
  headerOutcomes: {
    borderRight: '1px solid rgba(0, 83, 55, 0.20)',
    flex: 1,
  },
  widerItem: {
    width: '115px',
  },
  narrowItem: {
    width: '40px',
  },
  valuesParent: {
    '& > :first-child': {
      backgroundColor: 'background.lighter',
    },
  },
};

type Props = {
  eventId: string;
};

type MonitoringEventData = {
  marketName: string;
  maxLiability: number;
  currentLiability: number;
  currentShare: number;
  outcomes: { name: string; value: number }[];
};

const LiabilityEventDataDetails = ({ eventId }: Props) => {
  const { data: event } = useQuery<PaginatedData<MonitoringEventData>>([QUERY_KEYS.event, eventId], {
    queryFn: () =>
      getData(`risk-management/monitoring/events/${eventId}`, {
        product: 'pre-match',
      }),
    enabled: !!eventId,
  });

  return (
    <Box sx={styles.container}>
      <Box sx={styles.header}>
        <Box sx={styles.headerItem}>Market Name</Box>
        <Box sx={styles.headerItem}>Max. Liability</Box>
        <Box sx={styles.headerItem}>Current Liability</Box>
        <Box sx={styles.percentageHeaderItem}>%</Box>
        <Box sx={styles.headerOutcomes}></Box>
      </Box>
      <Box sx={styles.valuesParent}>
        {event?.items.map((event) => (
          <LiabilityEventDetailsRow
            key={event.marketName}
            currentLiability={event.currentLiability}
            marketName={event.marketName}
            maxLiability={event.maxLiability}
            outcomes={event.outcomes}
            percentageValue={event.currentShare}
          />
        ))}
      </Box>
    </Box>
  );
};

export default LiabilityEventDataDetails;
