import { Box, Typography } from '@mui/material';
import React from 'react';

type Commission = {
  '1': number;
  '2': number;
  '3': number;
  '4-7': number;
  '8-9': number;
  '10+': number;
};

type Props = {
  commission: Commission;
};

const styles = {
  container: {
    width: 1200,
  },
  bold: {
    fontWeight: 600,
  },
};

const AgentsReportsCommissions = ({ commission }: Props) => {
  return (
    <Box sx={styles.container}>
      <Typography sx={styles.bold}>Per Pick</Typography>
      <Typography sx={styles.bold}>Player on: Per Pick</Typography>
      <Typography>1: {commission[1]}%</Typography>
      <Typography>2: {commission[2]}%</Typography>
      <Typography>3: {commission[3]}%</Typography>
      <Typography>4-7: {commission['4-7']}%</Typography>
      <Typography>8-9: {commission['8-9']}%</Typography>
      <Typography>10+: {commission['10+']}%</Typography>
    </Box>
  );
};

export default AgentsReportsCommissions;
