import { GridRowClassNameParams, GridValueGetterParams } from '@mui/x-data-grid';
import dayjs, { Dayjs } from 'dayjs';
import { has } from 'lodash-es';
import { DateRangeOption, MarketsGroupedByMarketType } from '../@types';
import {
  Bet,
  BettingSlipFrom,
  BettingSlipWithMonitoringInfo,
  CustomerWalletTransaction,
  EventBase,
  EventPartScore,
  EventWithResults,
  Market,
  Outcome,
  SpecialValue,
} from '../@types/api';
import { LiveIcon, MixIcon, PreMatchIcon } from '../components/icons';
import { SpecialValueFormInput } from '../hooks/useTicketAuthorization';

export const getPageSizeOptions = (rowCount: number): number[] => {
  if (rowCount <= 25) return [25];
  if (rowCount <= 50) return [25, 50];
  return [25, 50, 100];
};

export const splitCamelcaseString = (string: string): string => {
  const splitWords = string.split(/(?=[A-Z])/);

  const capitalizeWords = splitWords.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  return capitalizeWords.join(' ');
};

export const setValueAsNumberOrNull = (value: string) => {
  return !value ? null : Number(value);
};

export const isNumeric = (value: string) => {
  return !isNaN(Number(value));
};

type Obj = EventBase | EventWithResults | Market | Outcome | SpecialValue;

export const getLatestValue = (obj: Obj, key: string) =>
  // @ts-expect-error
  has(obj, `latestChanges.${key}`) ? obj.latestChanges[key] : obj[key];

export const getOutcomeCellColor = (outcome: Outcome) => {
  const latestChanges = outcome.latestChanges;

  if (!outcome?.odds || !latestChanges?.odds) return 'black';

  return latestChanges?.odds && Number(latestChanges.odds) < Number(outcome?.odds) ? 'red' : 'green';
};

export const sortMarketsByPositionAndName = (markets: (Market | MarketsGroupedByMarketType)[]) => {
  const marketsCopy = [...markets];

  return marketsCopy.sort((a, b) => {
    if (a.position && b.position) {
      return a.position - b.position;
    }
    if (a.position) {
      return -1;
    }
    if (b.position) {
      return 1;
    }
    return a.name?.localeCompare(b?.name);
  });
};

export const getBettingSlipFromIcon = (eventFrom: BettingSlipFrom) => {
  switch (eventFrom) {
    case 'Pre-match':
      return PreMatchIcon;
    case 'In-play':
      return LiveIcon;
    case 'Mix':
      return MixIcon;
    default:
      return PreMatchIcon;
  }
};

export const isDev = () => process.env.NODE_ENV === 'development';

export const initializeTicketAuthorizationFormData = (ticketDetails: BettingSlipWithMonitoringInfo | undefined) => {
  if (!ticketDetails) {
    return {
      totalStakeAmount: '',
      specialValues: {},
      odds: {},
    };
  }

  const specialValues = ticketDetails.bets.reduce((acc: Record<string, SpecialValueFormInput[]>, bet: Bet) => {
    const specialValue = bet.specialValues?.[0]?.value ?? null;
    return specialValue !== null
      ? {
          ...acc,
          [bet.id]: [{ id: bet.outcome.id, name: specialValue.toString(), odds: bet.odds }],
        }
      : acc;
  }, {});

  const odds = ticketDetails.bets.reduce((acc: Record<string, string>, bet: Bet) => {
    if (bet.specialValues.length) {
      return acc;
    }

    return {
      ...acc,
      [bet.outcome.id]: bet.odds.toString(),
    };
  }, {});

  return {
    totalStakeAmount: ticketDetails.totalStakeAmount.toString(),
    specialValues,
    odds,
  };
};

export const isUUID = (value: string): boolean => {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

  return uuidRegex.test(value);
};

export const generateDateRangeTimestamps = (dateRangeOption: DateRangeOption) => {
  const now = dayjs();

  let fromTimestamp: Dayjs;
  let toTimestamp: Dayjs;

  switch (dateRangeOption) {
    case 'today':
      fromTimestamp = now.startOf('day');
      toTimestamp = now.endOf('day');
      break;
    case 'yesterday':
      fromTimestamp = now.subtract(1, 'day').startOf('day');
      toTimestamp = now.subtract(1, 'day').endOf('day');
      break;
    case 'this_week':
      fromTimestamp = now.startOf('week');
      toTimestamp = now.endOf('week');
      break;
    case 'last_week':
      fromTimestamp = now.subtract(1, 'week').startOf('week');
      toTimestamp = now.subtract(1, 'week').endOf('week');
      break;
    case 'last_7_days':
      fromTimestamp = now.subtract(7, 'day').startOf('day');
      toTimestamp = now.endOf('day');
      break;
    case 'last_30_days':
      fromTimestamp = now.subtract(30, 'day').startOf('day');
      toTimestamp = now.endOf('day');
      break;
    case 'this_month':
      fromTimestamp = now.startOf('month');
      toTimestamp = now.endOf('month');
      break;
    case 'last_month':
      fromTimestamp = now.subtract(1, 'month').startOf('month');
      toTimestamp = now.subtract(1, 'month').endOf('month');
      break;
    default:
      fromTimestamp = now.startOf('day');
      toTimestamp = now.endOf('day');
  }

  return { fromTimestamp, toTimestamp };
};

export const walletValueGetter = (
  walletKey: 'mainWallet' | 'bonusWallet',
  field: 'amountIn' | 'amountOut' | 'balance'
) => {
  return (params: GridValueGetterParams<CustomerWalletTransaction>) => {
    const wallet = params.row[walletKey];

    return wallet && wallet[field] ? wallet[field].toFixed(2) : '';
  };
};

export const getTotalsRowClass = (params: GridRowClassNameParams) => {
  const rowId = params.id ? params.id : params.row.userid;
  if (rowId === 'totals' || rowId === 'Totals:') {
    return 'totals';
  }
  return '';
};

export const formatScore = (mainEventPartScore: EventPartScore | null) => {
  const { valueAway, valueHome } = mainEventPartScore || {};

  if ((valueHome || valueHome === 0) && (valueAway || valueAway === 0)) {
    return `${valueHome} - ${valueAway}`;
  }
  return null;
};
