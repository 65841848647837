import type { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import type { Competition, CompetitionList } from '../../@types/api';
import { QUERY_KEYS } from '../../constants';

import { createEditColumn, createIsActiveColumn, createSportIconColumn } from '../../helpers/columns';
import { defaultColumnsCompetitions } from '../../helpers/table';
import useMutateData from '../../hooks/useMutateData';
import { usePagination } from '../../hooks/usePagination';
import useSort from '../../hooks/useSort';
import { createColumn } from '../../utils';
import TableTemplate from '../templates/TableTemplate';

const CompetitionsPage = () => {
  const {
    data: competitionsData,
    updateQueryParams,
    isFetching,
    isLoading,
    changeQuery,
  } = usePagination<CompetitionList>('competitions');
  const { deleteData, updateData } = useMutateData('competitions', [QUERY_KEYS.competitions]);
  const { handleSort } = useSort(changeQuery);

  const columns: GridColDef[] = [
    createColumn('id', 'ID', {
      sortable: false,
    }),
    createColumn('sportName', 'Sport', {
      valueGetter: (params: GridValueGetterParams<Competition>) => params.row.sport.name,
    }),
    createSportIconColumn<Competition>(),
    createColumn('name', 'Competition Name'),
    createColumn('shortName', 'Competition Short Name'),
    createColumn('position', 'Position'),
    createIsActiveColumn<Competition>({
      updateData,
    }),
    createEditColumn<Competition>({
      deleteData,
    }),
  ];

  const handleSearch = (value: string | null) => {
    changeQuery({ search: value });
  };

  return (
    <TableTemplate
      rows={competitionsData?.items || []}
      columns={columns}
      loading={isFetching || isLoading}
      rowCount={competitionsData?.count || 0}
      defaultVisibleColumns={defaultColumnsCompetitions}
      createItemBtnLabel="Add new competition"
      handlePaginationModelChange={updateQueryParams}
      handleSearch={handleSearch}
      handleSort={handleSort}
      changeQuery={changeQuery}
      formType="competitions"
    />
  );
};

export default CompetitionsPage;
