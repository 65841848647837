import { zodResolver } from '@hookform/resolvers/zod';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { IconButton, Stack, TextField, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash-es';
import { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import type { DialogType, StyleObj } from '../../../@types';
import { MarketType, SportEventPartsResponse } from '../../../@types/api';
import {
  DISPLAY_TYPE_OPTIONS,
  MARKET_TYPE_TYPE_OPTIONS,
  MARKET_TYPE_USED_FOR_OPTIONS,
  MODEL_TYPE_OPTIONS,
  QUERY_KEYS,
} from '../../../constants';
import { useModal } from '../../../contexts/ModalContext';
import useMutateData from '../../../hooks/useMutateData';
import { MarketTypeFormData, marketTypeFormSchema } from '../../../schema';
import { getModelTypeLabel } from '../../../schema/helpers';
import { getData } from '../../../utils/api';
import FormFieldStack from '../../atoms/FormFieldStack';
import Switch from '../../atoms/Switch';
import FormModalLayout from '../../layouts/FormModalLayout';
import FormNumberInput from '../../molecules/FormNumberInput';
import FormSelect from '../../molecules/FormSelect';
import SportSelect from '../../molecules/SportSelect';

const DEFAULT_FORM_DATA: MarketTypeFormData = {
  name: '',
  shortName: '',
  sportId: '',
  description: '',
  position: null,
  isActive: false,
  specialValueModelTypes: [],
  isSingleDisplayType: 1,
  isCollapsedMode: false,
  sportEventPartId: null,
  usedFor: ['pre_match'],
  type: 'match',
};

const styles: StyleObj = {
  position: {
    width: 80,
  },
  icon: {
    fontSize: '2rem',
  },
};

type Props = DialogType;

const MarketTypeForm = ({ closeModal }: Props) => {
  const { item } = useModal<MarketType>();
  const { createData, updateData } = useMutateData('market-types', [QUERY_KEYS.marketTypes]);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    getValues,
    watch,
    clearErrors,
  } = useForm<MarketTypeFormData>({
    defaultValues: DEFAULT_FORM_DATA,
    resolver: zodResolver(marketTypeFormSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'specialValueModelTypes',
  });

  const sportId = watch('sportId') || item?.sport.id;

  const { data: sportEventParts } = useQuery([QUERY_KEYS.eventParts, sportId], {
    queryFn: (): Promise<SportEventPartsResponse> =>
      getData('sport-event-parts', {
        sportId,
      }),
    enabled: !!sportId,
  });

  useEffect(() => {
    if (item) {
      reset({
        ...item,
        sportId: item.sport.id,
        specialValueModelTypes:
          item.specialValueModelTypes?.map((type, index) => ({
            id: index,
            modelType: type ?? 'event',
          })) ?? [],
        isSingleDisplayType: item.isSingleDisplayType ? 1 : 0,
      });
    }
  }, [item, reset]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type !== 'change') return;

      if (name === 'sportId' && value?.sportEventPartId) {
        setValue('sportEventPartId', null);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  const { specialValueModelTypes } = getValues();

  useEffect(() => {
    if (item) return;

    if (specialValueModelTypes?.length === 1) {
      setValue('isSingleDisplayType', 0);
      clearErrors('isSingleDisplayType');
    } else if (specialValueModelTypes?.length === 0) {
      setValue('isSingleDisplayType', 1);
      clearErrors('isSingleDisplayType');
    } else {
      setValue('isSingleDisplayType', null);
    }
  }, [getValues, setValue, clearErrors, specialValueModelTypes, item]);

  const handleClose = () => {
    reset(DEFAULT_FORM_DATA);
    closeModal?.();
  };

  const onFormSubmit = (data: MarketTypeFormData) => {
    const prepareData = (data: MarketTypeFormData) => ({
      ...data,
      isSingleDisplayType: data.isSingleDisplayType === null ? null : !!data.isSingleDisplayType,
      specialValueModelTypes: !isEmpty(data.specialValueModelTypes)
        ? data.specialValueModelTypes?.map((type) => (type.modelType === 'event' ? null : type.modelType))
        : null,
    });

    if (item) {
      updateData(item.id, prepareData(data), () => {
        handleClose();
      });
    } else {
      createData(prepareData(data), () => {
        handleClose();
      });
    }
  };

  return (
    <FormModalLayout
      onSave={handleSubmit(onFormSubmit)}
      label={item ? 'Edit market' : 'Add new market'}
      onClose={handleClose}
      isEdit={!!item}
    >
      <TextField error={!!errors.name} helperText={errors.name?.message} label="Name" {...register('name')} required />
      <TextField
        error={!!errors.shortName}
        helperText={errors.shortName?.message}
        label="Short name"
        {...register('shortName')}
      />
      <SportSelect control={control} name="sportId" error={errors.sportId} closeMenuOnSelect required />
      <TextField
        error={!!errors.description}
        helperText={errors.description?.message}
        label="Description"
        {...register('description')}
        multiline
      />
      <FormSelect
        label="Used for"
        name="usedFor"
        control={control}
        error={errors.usedFor}
        options={MARKET_TYPE_USED_FOR_OPTIONS}
        multiple
      />
      <FormSelect label="Type" name="type" control={control} error={errors.type} options={MARKET_TYPE_TYPE_OPTIONS} />
      <FormFieldStack label="Position">
        <FormNumberInput name="position" control={control} error={errors.position} sx={styles.position} />
      </FormFieldStack>
      <FormFieldStack label="Status">
        <Controller name="isActive" control={control} render={({ field }) => <Switch {...field} ref={null} />} />
      </FormFieldStack>
      {item && item.specialValueModelTypes?.length > 0 && (
        <Typography variant="h6" fontWeight={600}>
          Special values
        </Typography>
      )}
      {!item && (
        <Stack direction="row" alignItems="center">
          <IconButton onClick={() => append({ modelType: 'event', id: fields.length })} disabled={fields.length >= 3}>
            <AddCircleIcon color={fields.length >= 3 ? 'disabled' : 'primary'} sx={styles.icon} />
          </IconButton>
          <Typography variant="h6" color="text.primary">
            Add special value
          </Typography>
        </Stack>
      )}

      {fields.map((field, index) => (
        <Stack spacing={2} key={field.id} sx={styles.specialValueStack}>
          <Typography variant="h6" fontWeight={600} sx={styles.specialValueLabel}>
            {getModelTypeLabel(index)} special value
          </Typography>
          <FormSelect
            label="Model type"
            name={`specialValueModelTypes.${index}.modelType`}
            control={control}
            error={errors.specialValueModelTypes?.[index]?.modelType}
            options={MODEL_TYPE_OPTIONS}
            disabled={!!item}
          />
          {!item && (
            <Stack direction="row" alignItems="center">
              <IconButton onClick={() => remove(index)}>
                <RemoveCircleIcon color="error" sx={styles.icon} />
              </IconButton>
              <Typography variant="h6" color="text.primary">
                Remove special value
              </Typography>
            </Stack>
          )}
        </Stack>
      ))}

      <FormSelect
        label="Display type"
        name="isSingleDisplayType"
        control={control}
        error={errors.isSingleDisplayType}
        options={DISPLAY_TYPE_OPTIONS}
      />
      <FormFieldStack label="Collapsed">
        <Controller name="isCollapsedMode" control={control} render={({ field }) => <Switch {...field} ref={null} />} />
      </FormFieldStack>
      <FormSelect
        label="Event parts"
        name="sportEventPartId"
        control={control}
        error={errors.sportEventPartId}
        options={
          sportEventParts?.items?.map((part) => ({
            id: part.id,
            name: part.eventPartName,
          })) ?? []
        }
        disabled={!sportId}
      />
    </FormModalLayout>
  );
};

export default MarketTypeForm;
