import { Info, Search } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import { GridColDef, GridColumnGroupingModel, GridRenderCellParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { StyleObj } from '../../../../@types';
import { Transaction } from '../../../../@types/api';
import { usePagination } from '../../../../hooks/usePagination';
import useSort from '../../../../hooks/useSort';
import { createColumn } from '../../../../utils';
import TableIconContainer from '../../../atoms/TableIconContainer';
import TicketDetailsModal from '../../../modals/TicketDetailsModal';
import DateRangeSelect, { DayjsTimestamp } from '../../../molecules/DateRangeSelect';
import TableTemplate from '../../../templates/TableTemplate';

const styles: StyleObj = {
  container: {
    position: 'relative',
    height: 'calc(100% - 200px)',
  },
  table: {
    border: 'none',
    '& .MuiDataGrid-columnHeader': {
      border: `1px solid rgba(0, 83, 55, 0.20)`,
      backgroundColor: 'background.lightGreen',
      '&:first-of-type': {
        borderRadius: 0,
      },
      '&:last-of-type': {
        borderRadius: 0,
        width: '100% !important',
        maxWidth: 'unset !important',
      },
      '& .MuiDataGrid-sortIcon': {
        color: 'grey.700',
      },
    },
    '& .MuiDataGrid-columnHeadersInner': {
      width: '100%',
      '> div': {
        width: '100%',
      },
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 700,
      color: 'grey.700',
    },
    '& .MuiDataGrid-row:nth-of-type(even)': {
      backgroundColor: 'background.light',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    '& .MuiDataGrid-columnHeaders:hover .MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
    },
    '& .customGroupHeader': {
      backgroundColor: 'primary.main',
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        justifyContent: 'center',
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 700,
        color: 'white',
      },
    },
    '& .MuiDataGrid-columnHeader--emptyGroup': {
      backgroundColor: 'primary.main',
      '&:first-of-type': {
        borderTopLeftRadius: 8,
      },
      '&:last-of-type': {
        borderTopRightRadius: 8,
      },
    },
  },
};

const columnGroupingModel: GridColumnGroupingModel = [
  {
    groupId: 'Main',
    description: 'Main',
    headerClassName: 'customGroupHeader',
    children: [{ field: 'mainIn' }, { field: 'mainOut' }, { field: 'mainBalance' }],
  },
  {
    groupId: 'Bonus',
    description: 'Bonus',
    headerClassName: 'customGroupHeader',
    children: [{ field: 'bonusIn' }, { field: 'bonusOut' }, { field: 'bonusBalance' }],
  },
];

type Props = {
  userId?: string;
  columns: GridColDef[];
  endpoint: string;
};

const WalletTransactionPage = <T,>({ userId, columns, endpoint }: Props) => {
  const [date, setDate] = useState<DayjsTimestamp>({
    fromTimestamp: dayjs().startOf('day'),
    toTimestamp: dayjs().endOf('day'),
  });

  const [modalBetslipId, setModalBetslipId] = useState<string | null>(null);

  const { data, changeQuery, updateQueryParams, isLoading, isFetching } = usePagination<T>(
    endpoint,
    {
      page: 1,
      limit: 25,
      userId,
      fromTimestamp: date.fromTimestamp.valueOf(),
      toTimestamp: date.toTimestamp.valueOf(),
    },
    undefined,
    'wallet'
  );

  const { handleSort } = useSort(changeQuery);

  const handleDateChange = (newDate: DayjsTimestamp) => {
    setDate(newDate);
  };

  useEffect(() => {
    changeQuery({
      fromTimestamp: date.fromTimestamp.valueOf(),
      toTimestamp: date.toTimestamp.valueOf(),
    });
  }, [date, changeQuery]);

  const openBetslipModal = (betslipId: string) => {
    setModalBetslipId(betslipId);
  };

  const columnsWithDescription = [
    ...columns,
    createColumn('description', 'Description', {
      sortable: false,
      renderCell: (params: GridRenderCellParams<Transaction>) => {
        const isPlaceBetType = params.row.type === 'place_bet';

        return (
          <TableIconContainer onClick={() => isPlaceBetType && openBetslipModal(params.row.orderId)}>
            <Tooltip title={params.row.description} sx={{ cursor: isPlaceBetType ? 'pointer' : 'default' }}>
              {isPlaceBetType ? <Search color="primary" /> : <Info color="primary" />}
            </Tooltip>
          </TableIconContainer>
        );
      },
    }),
  ];

  return (
    <Box sx={styles.container}>
      <DateRangeSelect date={date} onDateChange={handleDateChange} />
      <TableTemplate
        columns={columnsWithDescription}
        rows={data?.items || []}
        rowCount={data?.count || 0}
        handleSort={handleSort}
        sx={styles.table}
        changeQuery={changeQuery}
        experimentalFeatures={{ columnGrouping: true }}
        columnGroupingModel={columnGroupingModel}
        handlePaginationModelChange={updateQueryParams}
        loading={isFetching || isLoading}
      />
      {modalBetslipId && (
        <TicketDetailsModal
          id={modalBetslipId}
          onClose={() => {
            setModalBetslipId(null);
          }}
        />
      )}
    </Box>
  );
};

export default WalletTransactionPage;
