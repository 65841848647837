import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { useQuery } from '@tanstack/react-query';
import dayjs, { Dayjs } from 'dayjs';
import { capitalize, isEmpty } from 'lodash-es';
import { Fragment, useEffect, useState } from 'react';
import { StyleObj } from '../../@types';
import { Competition, Event, EventExclusions, EventList, ExcludedEntity, Sport, Tournament } from '../../@types/api';
import { QUERY_KEYS } from '../../constants';
import { useModal } from '../../contexts/ModalContext';
import { useInvalidateQuery } from '../../hooks/useInvalidateQuery';
import useMutateData from '../../hooks/useMutateData';
import { useCompetitions, useEvents, useSports, useTournaments } from '../../queries';
import { getData } from '../../utils/api';

const styles: StyleObj = {
  container: { display: 'flex', gap: 3 },
  menu: {
    maxHeight: 360,
  },
  eventNameWrapper: {
    display: 'flex',
    justifyContent: 'center',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    overflow: 'hidden',
    border: '1px solid rgba(0, 83, 55, 0.20)',
    '& > div:first-child': {
      borderRight: '1px solid rgba(0, 83, 55, 0.20)',
    },
  },
  eventNameBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'background.lightGreen',
    py: 2,
  },
  excludedEntitiesContainer: {
    pt: 1.5,
    pl: 1.5,
    maxHeight: 200,
    overflowY: 'scroll',
  },
};

type SelectOption = {
  id: string;
  name: string;
};

type FilterEventsData = {
  fromTimestamp?: number | Dayjs;
  toTimestamp?: number | Dayjs;
  sports: string[];
  competitions: string[];
  tournaments: string[];
  events: string[];
};

type EntitiesToExlude = keyof EventExclusions;

type EntitiesWithCheckboxToExlude = Exclude<EntitiesToExlude, 'events'>;

type DateChangeHandler = (date: number | Dayjs | null, type: 'from' | 'to') => void;

type SelectField = {
  entity: EntitiesToExlude;
  data: Sport[] | Competition[] | Tournament[] | EventList[] | undefined;
};

type SelectFiels = SelectField[];

const queryParams = {
  limit: 100,
};

const defaultValues = {
  fromTimestamp: dayjs(),
  toTimestamp: dayjs().add(14, 'day'),
  sports: [],
  competitions: [],
  tournaments: [],
  events: [],
};

const MatchCombiningTab = () => {
  const [filterData, setFilterData] = useState<FilterEventsData>(defaultValues);
  const [checkedEntities, setCheckedEntities] = useState<EventExclusions>({
    sports: [],
    competitions: [],
    tournaments: [],
    events: [],
  });

  const { item, closeModal } = useModal<EventList>();

  const { data: event } = useQuery({
    queryKey: ['events', { id: item?.id }],
    queryFn: (): Promise<{ item: Event }> => getData(`events/${item?.id}`),
    select: (data) => data.item,
    enabled: !!item?.id,
  });

  const { createData: exludeEntities } = useMutateData(`/events/${item?.id}/exclude-entities`, [QUERY_KEYS.events]);
  const invalidateData = useInvalidateQuery();

  // on event change, set checkedEntities
  useEffect(() => {
    if (event?.eventExclusions) {
      const getExludedData = (entityType: EntitiesToExlude) => {
        if (isEmpty(event?.eventExclusions)) return [];

        return event?.eventExclusions[entityType];
      };

      const exludedSports = getExludedData('sports');
      const exludedCompetitions = getExludedData('competitions');
      const exludedTournaments = getExludedData('tournaments');
      const exludedEvents = getExludedData('events');

      setCheckedEntities({
        sports: exludedSports,
        competitions: exludedCompetitions,
        tournaments: exludedTournaments,
        events: exludedEvents,
      });
    }
  }, [event?.eventExclusions]);

  const { data: sportsData } = useSports(queryParams);
  const { data: competitionsData } = useCompetitions(filterData.sports, queryParams);
  const { data: tournamentsData } = useTournaments(filterData.competitions, queryParams);
  const { data: eventsData } = useEvents(filterData.tournaments, {
    ...queryParams,
    fromTimestamp: filterData.fromTimestamp?.valueOf(),
    toTimestamp: filterData.toTimestamp?.valueOf(),
  });

  // on eventsData change, add checked events to filterData.events if they don't exist
  useEffect(() => {
    if (eventsData?.items) {
      // find events that are in checkedEntities.events but not in filterData.events
      const newEventsToAdd = checkedEntities.events.filter(
        (event) => !filterData.events.includes(event.id) && eventsData.items.some((item) => item.id === event.id)
      );

      setFilterData((prev) => ({ ...prev, events: [...prev.events, ...newEventsToAdd.map((event) => event.id)] }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsData?.items]);
  const onFormSubmit = () => {
    exludeEntities(
      {
        sportIds: checkedEntities.sports.map((sport) => sport.id),
        competitionIds: checkedEntities.competitions.map((competition) => competition.id),
        tournamentIds: checkedEntities.tournaments.map((tournament) => tournament.id),
        eventIds: checkedEntities.events.map((event) => event.id),
      },
      () => {
        closeModal?.();
        invalidateData([QUERY_KEYS.events]);
      }
    );
  };

  const handleEntityCheck = (item: ExcludedEntity, entityType: EntitiesWithCheckboxToExlude) => {
    const entity = checkedEntities[entityType as keyof typeof checkedEntities];

    if (entity.some((checkedItem) => checkedItem.id === item.id)) {
      setCheckedEntities((prev) => ({
        ...prev,
        [entityType]: prev[entityType].filter((checkedItem) => checkedItem.id !== item.id),
      }));
    } else {
      setCheckedEntities((prev) => ({
        ...prev,
        [entityType]: [...prev[entityType], item],
      }));
    }
  };

  const isChecked = (option: SelectOption, entityType: EntitiesWithCheckboxToExlude) => {
    return checkedEntities[entityType as keyof typeof checkedEntities]?.some(
      (checkedItem) => checkedItem.id === option.id
    );
  };

  const selectFields: SelectFiels = [
    {
      entity: 'sports',
      data: sportsData?.items,
    },
    {
      entity: 'competitions',
      data: competitionsData?.items,
    },
    {
      entity: 'tournaments',
      data: tournamentsData?.items,
    },
    {
      entity: 'events',
      data: eventsData?.items,
    },
  ];

  const renderSelectedValue = (
    selected: unknown,
    options: Sport[] | Competition[] | Tournament[] | EventList[] | undefined
  ) => {
    if (Array.isArray(selected) && options) {
      return selected?.map((selectedId, index) => {
        const selectedItem = options?.find((option) => {
          return option.id === selectedId;
        });

        return `${selectedItem?.name}${index !== selected.length - 1 ? ', ' : ''}`;
      });
    }
  };

  const onDateChange: DateChangeHandler = (date, type) => {
    if (date) {
      setFilterData((prev) => ({ ...prev, [`${type}Timestamp`]: date }));
    }
  };

  const onEntityCheck = (option: SelectOption, field: SelectField) => {
    if (field.entity !== 'events') {
      handleEntityCheck(
        {
          id: option.id,
          entityName: option.name,
        },
        field.entity
      );
    }
  };

  const onEntitySelect = (e: SelectChangeEvent<string[]>, field: SelectField) => {
    const selectedIds = e.target.value as string[];

    const checkedEventsUpdater = (prev: EventExclusions, entityType: EntitiesToExlude) => {
      const updatedEvents: ExcludedEntity[] = [...checkedEntities.events];
      // if selectedIds has events that are not in checkedEntities.events, add them to updatedEvents
      selectedIds.forEach((selectedId) => {
        if (!prev.events.some((event) => event.id === selectedId)) {
          const event = field.data?.find((item) => item.id === selectedId);
          if (event) {
            updatedEvents.push({ id: event.id, entityName: event.name });
          }
        }
      });
      // if checkedEntities.events has events that are not in selectedIds, but they are in filterData.events, remove them from updatedEvents
      prev.events.forEach((event) => {
        if (!selectedIds.includes(event.id) && filterData.events.includes(event.id)) {
          updatedEvents.splice(
            updatedEvents.findIndex((item) => item.id === event.id),
            1
          );
        }
      });

      return {
        ...prev,
        [entityType]: updatedEvents,
      };
    };

    if (field.entity === 'sports') {
      setFilterData((prev) => ({ ...prev, sports: selectedIds, competitions: [], tournaments: [], events: [] }));
    }
    if (field.entity === 'competitions') {
      setFilterData((prev) => ({ ...prev, competitions: selectedIds, tournaments: [], events: [] }));
    }
    if (field.entity === 'tournaments') {
      setFilterData((prev) => ({ ...prev, tournaments: selectedIds, events: [] }));
    }

    if (field.entity === 'events') {
      setFilterData((prev) => ({ ...prev, events: selectedIds }));
      setCheckedEntities((prev) => checkedEventsUpdater(prev, field.entity));
    }
  };

  const onRemoveItem = (entity: ExcludedEntity, entityType: EntitiesToExlude) => {
    setCheckedEntities((prev) => ({
      ...prev,
      [entityType]: prev[entityType].filter((checkedItem) => checkedItem.id !== entity.id),
    }));

    if (entityType === 'events') {
      setFilterData((prev) => ({ ...prev, events: prev.events.filter((eventId) => eventId !== entity.id) }));
    }
  };

  const renderExludedItems = (entityType: EntitiesToExlude, entityData: ExcludedEntity[]) => {
    return entityData?.map((entity) => {
      if (entity) {
        return (
          <Box key={entity.id} display="flex" justifyContent="space-between" alignItems="center">
            <Typography>{entity.entityName}</Typography>
            <IconButton onClick={() => onRemoveItem(entity, entityType)}>
              <Close />
            </IconButton>
          </Box>
        );
      }
      return null;
    });
  };

  return (
    <Fragment>
      <DialogContent>
        <Stack spacing={2}>
          <Box sx={styles.container}>
            <DateTimePicker
              label="From"
              value={filterData.fromTimestamp}
              maxDateTime={filterData.toTimestamp}
              onChange={(value) => onDateChange(value, 'from')}
              ampm={false}
            />
            <DateTimePicker
              label="To"
              value={filterData.toTimestamp}
              minDateTime={filterData.fromTimestamp}
              onChange={(value) => onDateChange(value, 'to')}
              ampm={false}
            />
          </Box>
          {selectFields.map((field) => (
            <FormControl key={field.entity}>
              <InputLabel id="select-label">{capitalize(field.entity)}</InputLabel>
              <Select
                id="select"
                label={capitalize(field.entity)}
                name={field.entity}
                multiple
                value={filterData[field.entity]}
                renderValue={(selected) => renderSelectedValue(selected, field.data)}
                onChange={(e) => onEntitySelect(e, field)}
                MenuProps={{
                  sx: styles.menu,
                }}
              >
                {isEmpty(field.data) ? (
                  <MenuItem disabled>No {field.entity} available</MenuItem>
                ) : (
                  field.data?.map((option) => (
                    <MenuItem key={option.id} value={option.id} disabled={item?.id === option.id}>
                      {field.entity !== 'events' && (
                        <Checkbox
                          checked={isChecked(option, field.entity)}
                          onChange={() => onEntityCheck(option, field)}
                          onClick={(e) => e.stopPropagation()}
                        />
                      )}
                      {option.name}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          ))}

          <Box sx={styles.eventNameWrapper}>
            <Box sx={styles.eventNameBox}>
              <Typography variant="h5">Source event</Typography>
            </Box>

            <Box sx={styles.eventNameBox}>
              <Typography variant="h5">{item?.name}</Typography>
            </Box>
          </Box>
        </Stack>
        {Object.values(checkedEntities).some((entity) => entity.length > 0) && (
          <Typography variant="body2" px={1.5} py={0.5}>
            Cannot combine with
          </Typography>
        )}
        <Box sx={styles.excludedEntitiesContainer}>
          <Stack spacing={1}>
            {renderExludedItems('sports', checkedEntities.sports)}
            {renderExludedItems('competitions', checkedEntities.competitions)}
            {renderExludedItems('tournaments', checkedEntities.tournaments)}
            {renderExludedItems('events', checkedEntities.events)}
          </Stack>
        </Box>
        <Divider />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={closeModal}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onFormSubmit}>
          Save
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default MatchCombiningTab;
