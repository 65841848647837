import { Replay } from '@mui/icons-material';
import { Button, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { DialogType } from '../../../@types';
import { Customer } from '../../../@types/api';
import {
  BETTING_SLIP_TYPE_OPTIONS,
  LS_KEYS,
  QUERY_KEYS,
  SPORTSBOOK_FROM_OPTIONS,
  SPORTSBOOK_SOURCE_OPTIONS,
  SPORTSBOOK_WALLET_TYPE,
} from '../../../constants';
import { AdditionalQueryParams } from '../../../hooks/usePagination';
import usePersist from '../../../hooks/usePersist';
import FormSelect from '../../molecules/FormSelect';
import SportSelect from '../../molecules/SportSelect';
import FormAutocomplete from '../FormAutocomplete';

export const styles = {
  display: 'flex',
  flexDirection: 'column-reverse',
  alignItems: 'center',
  gap: '2',
};

const defaultValues = {
  walletType: undefined,
  sportId: undefined,
  from: undefined,
  userId: [],
  timeframe: undefined,
  device: undefined,
  bettingSlipType: undefined,
};

export type FilterSportsbookData = {
  userId?: string[];
  from?: string;
  sportId?: string;
  device?: string;
  walletType?: string;
  bettingSlipType?: string;
};

type Props = DialogType & {
  changeQuery: (data: AdditionalQueryParams) => void;
};

const FilterSportsbookReports = ({ changeQuery, closeModal }: Props) => {
  const { pathname } = useLocation();
  const page = pathname.split('/').pop();

  const { setPersistData, getPersistData, deletePersistData } = usePersist(false);

  const formDataKey = `${LS_KEYS.appFormFilter}-${pathname}`;
  const getSavedData = () => getPersistData<FilterSportsbookData>(formDataKey) || defaultValues;

  const { control, handleSubmit, reset } = useForm<FilterSportsbookData>({
    defaultValues: getSavedData(),
  });

  const handleFilterReset = () => {
    deletePersistData(formDataKey);
    reset(defaultValues);
    changeQuery(defaultValues);
    closeModal?.();
  };

  const onFormSubmit = (data: FilterSportsbookData) => {
    setPersistData<FilterSportsbookData>(formDataKey, data);
    changeQuery(data);
    closeModal?.();
  };

  return (
    <>
      <DialogTitle variant="h4" gutterBottom>
        Filter {page}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <FormAutocomplete<Customer, FilterSportsbookData>
            name="userId"
            control={control}
            queryKey={[QUERY_KEYS.customers]}
            apiService="crm"
            url="customer/search"
            getOptionLabel={(options, value) => {
              const option = options.find((option) => option?.userId === value);
              return option ? `${option.firstName} ${option.lastName} (${option.username})` : '';
            }}
            label="Player"
            optionsFilter="userId"
          />
          <FormSelect name="from" control={control} label="From" options={SPORTSBOOK_FROM_OPTIONS} />
          <FormSelect name="device" control={control} label="Source" options={SPORTSBOOK_SOURCE_OPTIONS} />
          {page !== 'sports' && <SportSelect name="sportId" control={control} />}
          <FormSelect name="walletType" control={control} label="Bets" options={SPORTSBOOK_WALLET_TYPE} />
          <FormSelect name="bettingSlipType" control={control} label="Type" options={BETTING_SLIP_TYPE_OPTIONS} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleFilterReset} startIcon={<Replay />} size="small">
          Reset filters
        </Button>
        <Button variant="outlined" onClick={closeModal}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit(onFormSubmit)}>
          Save
        </Button>
      </DialogActions>
    </>
  );
};

export default FilterSportsbookReports;
