import { Replay } from '@mui/icons-material';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import dayjs, { Dayjs } from 'dayjs';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { DialogType } from '../../@types';
import { Competition, PaginatedData, Sport, Tournament } from '../../@types/api';
import { HAS_STARTED_OPTIONS, LS_KEYS, PRODUCT_TYPES_OPTIONS, QUERY_KEYS } from '../../constants';
import { AdditionalQueryParams } from '../../hooks/usePagination';
import usePersist from '../../hooks/usePersist';
import { getData } from '../../utils/api';
import DateTimeSelect from '../molecules/DateTimeSelect';
import FormSelect from '../molecules/FormSelect';
import FormAutocomplete from './FormAutocomplete';

const defaultValues = {
  fromTimestamp: dayjs().startOf('day'),
  toTimestamp: dayjs().startOf('day').add(15, 'day'),
  sportId: undefined,
  competitionId: undefined,
  tournamentId: undefined,
  product: 'pre-match',
  isStarted: undefined,
};

type FilterLiabilityMonitoringData = {
  fromTimestamp: string | number | Dayjs;
  toTimestamp: string | number | Dayjs;
  sportId: Sport;
  competitionId: string;
  tournamentId: string;
  product: string;
  isStarted: number | boolean;
};

type Props = DialogType & {
  changeQuery: (data: AdditionalQueryParams) => void;
};

const styles = {
  datePickersWrapper: {
    display: 'flex',
    width: '320px',
    justifyContent: 'space-between',
  },
  datePicker: {
    width: '155px',
  },
};

const FilterLiabilityMonitoring = ({ changeQuery, closeModal }: Props) => {
  const { setPersistData, getPersistData, deletePersistData } = usePersist(false);

  const { pathname } = useLocation();

  const { data: sportsData } = useQuery([QUERY_KEYS.sports], {
    queryFn: (): Promise<PaginatedData<Sport>> =>
      getData('sports', {
        limit: 100,
        page: 1,
      }),
  });

  const formDataKey = `${LS_KEYS.appFormFilter}-${pathname}`;
  const getSavedData = () => getPersistData<FilterLiabilityMonitoringData>(formDataKey) || defaultValues;

  const { control, handleSubmit, reset, watch } = useForm<FilterLiabilityMonitoringData>({
    defaultValues: getSavedData(),
  });

  const sportId = watch('sportId');

  const { data: tournamentsData } = useQuery([QUERY_KEYS.tournaments, sportId], {
    queryFn: (): Promise<PaginatedData<Tournament>> =>
      getData('tournaments', {
        limit: 100,
        page: 1,
        sportId: sportId,
      }),
    enabled: !!sportId,
  });

  const handleFilterReset = () => {
    deletePersistData(formDataKey);
    reset(defaultValues);
    changeQuery(defaultValues);

    closeModal?.();
  };

  const onFormSubmit = (data: FilterLiabilityMonitoringData) => {
    const fromTimestamp = dayjs(data.fromTimestamp).valueOf();
    const toTimestamp = dayjs(data.toTimestamp).valueOf();
    const isStarted = data.isStarted === 1 ? true : false;

    const formatData = { ...data, fromTimestamp, toTimestamp, isStarted };

    setPersistData<FilterLiabilityMonitoringData>(formDataKey, formatData);
    changeQuery(formatData);
    closeModal?.();
  };

  return (
    <>
      <DialogTitle variant="h4" gutterBottom>
        Filter
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Box sx={styles.datePickersWrapper}>
            <Box sx={styles.datePicker}>
              <DateTimeSelect label="From" control={control} name="fromTimestamp" sx={styles.datePicker} />
            </Box>
            <Box sx={styles.datePicker}>
              <DateTimeSelect label="To" control={control} name="toTimestamp" sx={styles.datePicker} />
            </Box>
          </Box>
          <FormSelect control={control} name="sportId" label="Sport" options={sportsData?.items || []} />
          <FormAutocomplete<Competition, FilterLiabilityMonitoringData>
            name="competitionId"
            control={control}
            label="Competition"
            url="competitions"
            queryKey={[QUERY_KEYS.competitions, sportId]}
            queryParams={{
              sportId: sportId,
              isActive: true,
            }}
            disabled={!sportId}
            hookEnabled={!!sportId}
            getOptionLabel={(options, value) => {
              const option = options.find((option) => option.id === value);
              return option?.name || '';
            }}
          />
          <FormSelect control={control} name="tournamentId" label="Tournament" options={tournamentsData?.items || []} />
          <FormSelect control={control} name="product" label="Betting Offer" options={PRODUCT_TYPES_OPTIONS} />
          <FormSelect control={control} name="isStarted" label="Time Status" options={HAS_STARTED_OPTIONS} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleFilterReset} startIcon={<Replay />} size="small">
          Reset filters
        </Button>
        <Button variant="outlined" onClick={closeModal}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit(onFormSubmit)}>
          Save
        </Button>
      </DialogActions>
    </>
  );
};

export default FilterLiabilityMonitoring;
