import { useAuth } from 'neofusion-fe-shared';
import { useCallback } from 'react';
import { Permission } from '../@types';
import { PERMISSIONS } from '../constants/keycloak';

const usePermissions = () => {
  const user = useAuth();

  const hasPermission = useCallback(
    (permission: Permission) => {
      return user.permissions.includes(PERMISSIONS[permission]);
    },
    [user]
  );

  return {
    hasPermission,
  };
};

export default usePermissions;
